/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/function-component-definition */
import { IBreadcrumbs } from "components/atoms/Breadcrumbs/Breadcrumbs";
import { ICoordinatesDashboard } from "components/atoms/Map/MapOrg/MapOrg";
import { ICoordinates } from "components/atoms/Map/Maps";
import { INotification } from "models";
import React, { useState, createContext, useContext, SetStateAction, Dispatch, useMemo } from "react";
import { LocalStorageKeys, getInLocalStorage } from "utilities";

/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/

export enum actions {
  locationImportSuccess = "loc-imp-success",
  locationImportError = "loc-imp-error",
  userImportSuccess = "user-imp-success",
  userImportError = "user-imp-error",
}

type contextValues = {
  rememberMe: boolean;
  setRememberMe: Dispatch<SetStateAction<boolean>>;
  toggleChangeOrganization: boolean;
  setToggleChangeOrganization: Dispatch<SetStateAction<boolean>>;
  toggleChangeApplication: boolean;
  setToggleChangeApplication: Dispatch<SetStateAction<boolean>>;
  positions: ICoordinatesDashboard[];
  setPositions: Dispatch<SetStateAction<ICoordinatesDashboard[]>>;
  breadcrumbsHistory: IBreadcrumbs[];
  setBreadcrumbsHistory: Dispatch<SetStateAction<IBreadcrumbs[]>>;
  urlHistory: string[];
  setUrlHistory: Dispatch<SetStateAction<string[]>>;
  mapView: boolean;
  setMapView: Dispatch<SetStateAction<boolean>>;
  mapLocDetailView: boolean;
  setMapLocDetailView: Dispatch<SetStateAction<boolean>>;
  notifications: INotification[];
  setNotifications: Dispatch<SetStateAction<INotification[]>>;
  exportLocationFilter: string;
  setExportLocationFilter: Dispatch<SetStateAction<string>>;
  locEditMode: boolean;
  setLocEditMode: Dispatch<SetStateAction<boolean>>;
  navVisible: boolean;
  setNavVisible: Dispatch<SetStateAction<boolean>>;
  exportUserFilter: string;
  setExportUserFilter: Dispatch<SetStateAction<string>>;
  clearBasicInformation: () => void;
  modalLoader: { open: boolean; message: string } | null;
  setModalLoader: Dispatch<SetStateAction<{ open: boolean; message: string } | null>>;
};

const defaultValues = {
  rememberMe: true,
  setRememberMe: () => {},
  toggleChangeOrganization: false,
  setToggleChangeOrganization: () => {},
  toggleChangeApplication: false,
  setToggleChangeApplication: () => {},
  positions: [],
  setPositions: () => {},
  breadcrumbsHistory: [],
  setBreadcrumbsHistory: () => {},
  urlHistory: [],
  setUrlHistory: () => {},
  mapView: false,
  setMapView: () => {},
  mapLocDetailView: false,
  setMapLocDetailView: () => {},
  notifications: [],
  setNotifications: () => {},
  exportLocationFilter: "1",
  setExportLocationFilter: () => {},
  locEditMode: false,
  setLocEditMode: () => {},
  navVisible: false,
  setNavVisible: () => {},
  exportUserFilter: "1",
  setExportUserFilter: () => {},
  clearBasicInformation: () => {},
  modalLoader: null,
  setModalLoader: () => {},
};

const GlobalContext = createContext<contextValues>(defaultValues);

const GlobalContextProvider = (props: any) => {
  const { children } = props;

  //Array to render items
  const [rememberMe, setRememberMe] = useState<boolean>(true);
  const [toggleChangeOrganization, setToggleChangeOrganization] = useState<boolean>(true);
  const [toggleChangeApplication, setToggleChangeApplication] = useState<boolean>(true);
  const [positions, setPositions] = useState<ICoordinatesDashboard[]>([]);
  const [breadcrumbsHistory, setBreadcrumbsHistory] = useState<IBreadcrumbs[]>([]);
  const [urlHistory, setUrlHistory] = useState<string[]>([]);
  const [mapView, setMapView] = useState<boolean>(getInLocalStorage(LocalStorageKeys.LIST_VIEW) === "MAP");
  const [mapLocDetailView, setMapLocDetailView] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  //**Edits modes */
  const [locEditMode, setLocEditMode] = useState<boolean>(false);
  const [navVisible, setNavVisible] = useState<boolean>(false);

  //Export
  const [exportLocationFilter, setExportLocationFilter] = useState<string>("1");
  const [exportUserFilter, setExportUserFilter] = useState<string>("1");

  const [modalLoader, setModalLoader] = useState<{ open: boolean; message: string } | null>(null);

  const clearBasicInformation = () => {
    setPositions([]);
  };
  const contextValues = useMemo(
    () => ({
      rememberMe,
      setRememberMe,
      toggleChangeOrganization,
      setToggleChangeOrganization,
      toggleChangeApplication,
      setToggleChangeApplication,
      positions,
      setPositions,
      breadcrumbsHistory,
      setBreadcrumbsHistory,
      urlHistory,
      setUrlHistory,
      mapView,
      setMapView,
      notifications,
      setNotifications,
      exportLocationFilter,
      setExportLocationFilter,
      locEditMode,
      setLocEditMode,
      mapLocDetailView,
      setMapLocDetailView,
      navVisible,
      setNavVisible,
      exportUserFilter,
      setExportUserFilter,
      clearBasicInformation,
      modalLoader,
      setModalLoader,
    }),
    [
      rememberMe,
      toggleChangeOrganization,
      toggleChangeApplication,
      positions,
      breadcrumbsHistory,
      urlHistory,
      mapView,
      notifications,
      exportLocationFilter,
      locEditMode,
      mapLocDetailView,
      navVisible,
      setNavVisible,
      exportUserFilter,
      modalLoader,
    ],
  );

  return <GlobalContext.Provider value={contextValues}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("GlobalContext must be used within a GlobalContextProvider");
  }
  return context;
};

export { GlobalContext, GlobalContextProvider };
