//** MUI Imports*/
import { Box, IconButton, Skeleton, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
//** Components Imports*/
import GridOnIcon from "@mui/icons-material/GridOn";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Button, FilterChips, Input, Select, TableSearch } from "components";
import Translations from "layouts/components/Translations";
//** Context */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//** Hooks */
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { RoleGuard } from "guards";
import useCounters from "hooks/useCounters";
import { PrivateRoutes, Roles } from "models";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LocalStorageKeys, setInLocalStorage } from "utilities";
import { intToShortNotation } from "utilities/HelpService";
import useOrganizationsSearch, { OrgsSearchFields } from "../../../hooks/useOrganizationsSearch";

export default function AppListHeader({ onSearch }: { onSearch: Function }) {
  //** Context */
  const { setOrgListViewType, orgListViewType, organizationHasFilter } = useListAndSearchContext();
  const { counter, loading } = useCounters("organizations");

  const nameSearchFn = useOrganizationsSearch(OrgsSearchFields.name).orgNameSearch;
  const urlSearchFn = useOrganizationsSearch(OrgsSearchFields.url).orgUrlSearch;
  const statusSearch = useOrganizationsSearch(OrgsSearchFields.status).orgStatusSearch;
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { t } = useTranslation();
  const navigate = useNavigate();

  const optionsStatus = [t("OPTION-SELECT-1"), t("OPTION-SELECT-2")];

  //**Chips */
  const filterChips = [
    {
      key: "name",
      label: "INPUT-NAME",
      data: nameSearchFn.value,
    },
    {
      key: "url",
      label: "INPUT-URL",
      data: urlSearchFn.value,
    },
    {
      key: "status",
      label: "INPUT-STATUS",
      data: statusSearch.value,
    },
  ];
  //Filter Chips functions
  const handleDelete = (filterName: "name" | "url" | "status") => {
    if (filterName === "name") nameSearchFn.clearByKey();
    if (filterName === "url") urlSearchFn.clearByKey();
    if (filterName === "status") statusSearch.clearByKey();
    onSearch();
  };
  const handleDeleteAll = () => {
    nameSearchFn.clearSearch();
    urlSearchFn.clearSearch();
    statusSearch.clearSearch();
    onSearch();
  };

  const clearSearch = () => {
    nameSearchFn.clearSearch();
    urlSearchFn.clearSearch();
    statusSearch.clearSearch();
    onSearch();
  };

  return (
    <Box sx={{ backgroundColor: "background.default" }} display="flex" alignItems="center" py={8}>
      <Box width="100%">
        <Box display="flex" flexDirection={underMd ? "column" : "row"} flexWrap="wrap" gap={4} px={3} mb={1} alignItems="flex-start">
          <Box>
            <Box ml={2} mt={2} display="flex">
              <Box display="flex" alignItems="center" onClick={() => {}}>
                <CorporateFareIcon sx={{ fontSize: 24, mr: 4 }} />
                <Typography variant="h2" fontWeight="700">
                  <Translations text="GLOBAL-TITLE-ALL-ORGANIZATIONS" />
                </Typography>
                {loading ? (
                  <Skeleton variant="text" width={30} height={30} sx={{ ml: 2 }} />
                ) : (
                  <Typography variant="h5" ml={2} mt={2}>{` (${intToShortNotation(counter)})`}</Typography>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            ml="auto"
            display="flex"
            flexWrap="wrap"
            flexDirection={underMd ? "column" : "row"}
            alignItems={underMd ? "flex-end" : "center"}
            gap={6}
          >
            {!underMd && (
              <>
                {orgListViewType === "cards" ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setInLocalStorage(LocalStorageKeys.LIST_VIEW, "list");
                      setOrgListViewType("list");
                    }}
                  >
                    <IconButton>
                      <ListAltIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                    <Typography variant="h5">{t("GLOBAL-LIST-VIEW")}</Typography>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setInLocalStorage(LocalStorageKeys.LIST_VIEW, "cards");
                      setOrgListViewType("cards");
                    }}
                  >
                    <IconButton>
                      <GridOnIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                    <Typography variant="h5" sx={{ mr: 6 }}>
                      {t("GLOBAL-GRID-VIEW")}
                    </Typography>
                  </Box>
                )}
              </>
            )}
            <Box mt={underMd ? 4 : 0}>
              <TableSearch
                noOptionsComponent={
                  <Box>
                    <Stack direction={!underMd ? "row" : "column"} spacing={4} mb={4}>
                      <Input name="search-name" {...nameSearchFn} label={`${t("INPUT-NAME")}`} fullWidth />
                      <Select
                        name="search-status"
                        value={`${statusSearch.value || ""}`}
                        label={`${t("INPUT-STATUS")}`}
                        setValue={statusSearch.onChange}
                        options={optionsStatus}
                        fullWidth
                      />
                    </Stack>
                    <Input name="org-url-search" {...urlSearchFn} label={`${t("INPUT-URL")}`} fullWidth />
                  </Box>
                }
                onReset={clearSearch}
                onSubmit={(e: any) => {
                  e.preventDefault();
                  onSearch();
                }}
              />
            </Box>
            {!underMd && (
              <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager]}>
                <Button
                  name="create-organization"
                  onClick={() => {
                    navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.CREATE}`);
                  }}
                  label={t("BUTTON-CREATE")}
                  size="large"
                />
              </RoleGuard>
            )}
          </Box>
        </Box>
        {organizationHasFilter && (
          <Stack direction="row" spacing={4} alignItems="center" ml={4} mt={6} mb={12}>
            <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
          </Stack>
        )}
      </Box>
    </Box>
  );
}
