//** MUI imports */
import { CircularProgress, Grid, Skeleton, Theme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
//** Hooks */
import { useEmptyCardsLocationData } from "hooks";
//** Context */
import { useListAndSearchContext } from "context/ListsAndSearchContext";

//** Components imports */
import { EmptyListText } from "components";
import EmptyTableCards from "pages/locations/components/molecules/emptyTableCards/EmptyTableCards";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useOrganizationListContext } from "../../../context";
import { useGetOrganizations } from "../../../hooks";
import { OrgsSearchFields } from "../../../hooks/useOrganizationsSearch";
import { AppListHeader } from "../../atoms";
import { OrgListViewByType } from "../OrgListViewByType";
import { OrgLoadingListType } from "../OrgLoadingListType";

export default function OrganizationsTable() {
  //Table management
  const { loading, hasNextPage, loadMoreOrganizations, onSearch } = useGetOrganizations(true);
  const { organizationHasFilter } = useListAndSearchContext();
  const { organizationsArray } = useOrganizationListContext();

  const { organizationsPage, orgListViewType } = useListAndSearchContext();

  const underHeightMobile = useMediaQuery("(max-height: 600px)");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  //**Search */
  const { searchParamsOrgs } = useListAndSearchContext();
  const nameSearch = searchParamsOrgs.get(OrgsSearchFields.name);
  const urlSearch = searchParamsOrgs.get(OrgsSearchFields.url);
  const statusSearch = searchParamsOrgs.get(OrgsSearchFields.status);
  //Navigation for the table
  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore:
      organizationsPage === 0
        ? () => {}
        : () => loadMoreOrganizations({ name: nameSearch, is_suspended: statusSearch, website_url: urlSearch, nowPage: organizationsPage }),
    rootMargin: "0px 0px 400px 0px",
  });
  const hasData = organizationHasFilter?.length === 0 ? organizationsArray.length !== 0 : true;

  const { dataOrganizationsCards } = useEmptyCardsLocationData();

  const lessHeight = underHeightMobile ? "250" : "225";
  const tableHeight = underMd ? lessHeight : "180";
  return (
    <Box>
      <AppListHeader onSearch={onSearch} />
      <Box display="flex">
        <Box display="block" width="100%">
          <Box height={`calc(100dvh - ${`${tableHeight}px - ${organizationHasFilter ? "70px" : "0px"}`})`} overflow="scroll" ref={rootRef}>
            {loading && organizationsArray.length === 0 ? (
              <OrgLoadingListType />
            ) : (
              <Box pt={2}>
                <OrgListViewByType organizationsArray={organizationsArray} onSearch={onSearch} />
                {(hasNextPage || loading) && (
                  <>
                    {orgListViewType === "cards" ? (
                      <Box display="flex" justifyContent="center" mt={12}>
                        <CircularProgress ref={infiniteRef} />
                      </Box>
                    ) : (
                      <Skeleton width="100%" height={80} ref={infiniteRef} />
                    )}
                  </>
                )}

                <Box display="flex" justifyContent="center">
                  {!hasData && (
                    <Grid container spacing={4} justifyContent="center" my={8} ml={0} width="95vw">
                      {dataOrganizationsCards.map((item, index) => (
                        <EmptyTableCards
                          key={index}
                          data={dataOrganizationsCards[index]}
                          name="custom-radios-delivery"
                          gridProps={{ md: 4, sm: 12, xs: 12 }}
                        />
                      ))}
                    </Grid>
                  )}

                  {organizationHasFilter?.length > 0 && organizationsArray.length === 0 && <EmptyListText />}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
