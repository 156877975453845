/* eslint-disable max-len */
//** MUI Imports */
import { Box, Card, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { PrivateRoutes } from "models";
import { BasicInformation, OrganizationAddress, OrganizationContact } from "pages/organizations/components";
import { MapOrgView } from "pages/organizations/components/molecules/MapOrgView";
import EmailNotificationSection from "pages/organizations/pages/organizationsInformation/components/molecules/EmailNotificationSection/EmailNotificationSection";

//** Hooks */
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
//** Utils */
import { GoBackBreadcrumb, GoBackBreadcrumbMobile, SectionsTitles } from "components";
import { StepperOrgForm } from "pages/organizations/components/mobile";
import { useState } from "react";
import { BusinessOrg } from "../molecules/BusinessOrg";
import ButtonCreateOrganization from "../molecules/ButtonCreateOrganization/ButtonCreateOrganization";

export default function OrganizationCreateView() {
  //** Hooks */
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  if (underMd) {
    return (
      <Box>
        <Box mx={8} mb={12} pb={16}>
          <GoBackBreadcrumbMobile
            label={t("ORGANIZATION-DETAIL-TITLE")}
            description={t("ORGANIZATION-CREATE-SUBTITLE")}
            onClick={() => {
              navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`);
            }}
          />
          <StepperOrgForm activeStep={activeStep} />
          <Box mx={2}>
            {activeStep === 0 && <BasicInformation createView />}
            {activeStep === 1 && (
              <Box>
                <MapOrgView createView />
                <OrganizationAddress createView />
                <OrganizationContact />
              </Box>
            )}
            {activeStep === 2 && <BusinessOrg createView />}
            {activeStep === 3 && <EmailNotificationSection createView />}
            <Box mt={12} />
          </Box>
        </Box>
        <ButtonCreateOrganization setActiveStep={setActiveStep} activeStep={activeStep} />
      </Box>
    );
  }
  return (
    <Box mx={4} mb={0}>
      <GoBackBreadcrumb
        label={t("GO-BACK-ORGANIZATION-DASHBOARD")}
        onClick={() => {
          navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`);
        }}
      />
      <Card sx={{ py: 8, px: 6 }}>
        <SectionsTitles h2 title={`${t("ORGANIZATION-DETAIL-TITLE")}`} subTitle={`${t("ORGANIZATION-DETAIL-SUBTITLE")}`} />
        <BasicInformation createView />
        <MapOrgView createView />
        <OrganizationAddress createView />
        <OrganizationContact />
        <BusinessOrg createView />
        <EmailNotificationSection createView />
        <Box mt={12} />
        <ButtonCreateOrganization setActiveStep={setActiveStep} activeStep={activeStep} />
      </Card>
    </Box>
  );
}
