/* eslint-disable no-unused-vars */
// ** MUI Imports
import { styled, useTheme } from "@mui/material/styles";
import MuiSwipeableDrawer, { SwipeableDrawerProps } from "@mui/material/SwipeableDrawer";

// ** Type Import
import { LayoutProps } from "models/layoutsTypes";

interface Props {
  navWidth: number;
  navHover: boolean;
  navVisible: boolean;
  collapsedNavWidth: number;
  hidden: LayoutProps["hidden"];
  navigationBorderWidth: number;
  settings: LayoutProps["settings"];
  children: LayoutProps["children"];
  setNavHover: (values: boolean) => void;
  setNavVisible: (value: boolean) => void;
  navMenuProps: LayoutProps["verticalLayoutProps"]["navMenu"]["componentProps"];
}

const SwipeableDrawer = styled(MuiSwipeableDrawer)<SwipeableDrawerProps>({
  overflowX: "hidden",
  transition: "width .25s ease-in-out",
  zIndex: 100000,
  "& ul": {
    listStyle: "none",
  },
  "& .MuiListItem-gutters": {
    paddingLeft: 4,
    paddingRight: 4,
  },
  "& .MuiDrawer-paper": {
    left: "unset",
    right: "unset",
    overflowX: "hidden",
    transition: "width .25s ease-in-out, box-shadow .25s ease-in-out",
  },
});

const Drawer = (props: Props) => {
  // ** Props
  const {
    hidden,
    children,
    navHover,
    navWidth,
    settings,
    navVisible,
    setNavHover,
    navMenuProps,
    setNavVisible,
    collapsedNavWidth,
    navigationBorderWidth,
  } = props;

  // ** Hook
  const theme = useTheme();

  // ** Vars
  const { mode, navCollapsed } = settings;

  let flag = true;

  const drawerColors = () => {
    if (mode === "semi-dark") {
      return {
        backgroundColor: "customColors.darkBg",
        "& .MuiTypography-root": {
          color: `rgba(${theme.palette.customColors.dark}, 0.87)`,
        },
      };
    }
    return {
      backgroundColor: "background.default",
    };
  };

  // Drawer Props for Mobile & Tablet screens

  const MobileDrawerProps = {
    open: navVisible,
    onOpen: () => setNavVisible(true),
    onClose: () => setNavVisible(false),
    // ModalProps: {
    //   keepMounted: true, // Better open performance on mobile.
    // },
  };

  // Drawer Props for Laptop & Desktop screens
  const DesktopDrawerProps = {
    open: navVisible, //?before: true , now: navVisible
    onOpen: () => null,
    onClose: () => null,
    onMouseEnter: () => {
      // Declared flag to resolve first time flicker issue while trying to collapse the menu
      if (flag || navCollapsed) {
        setNavHover(true);
        flag = false;
      }
    },
    onMouseLeave: () => {
      if (navCollapsed) {
        setNavHover(false);
      }
    },
  };

  let userNavMenuStyle = {};
  let userNavMenuPaperStyle = {};
  if (navMenuProps && navMenuProps.sx) {
    userNavMenuStyle = navMenuProps.sx;
  }
  if (navMenuProps && navMenuProps.PaperProps && navMenuProps.PaperProps.sx) {
    userNavMenuPaperStyle = navMenuProps.PaperProps.sx;
  }
  // eslint-disable-next-line prefer-object-spread
  const userNavMenuProps = Object.assign({}, navMenuProps);
  delete userNavMenuProps.sx;
  delete userNavMenuProps.PaperProps;

  return (
    <SwipeableDrawer
      className="layout-vertical-nav"
      variant={hidden ? "temporary" : "permanent"}
      {...(hidden ? { ...MobileDrawerProps } : { ...DesktopDrawerProps })}
      PaperProps={{
        sx: {
          ...drawerColors(),
          width: navCollapsed && !navHover ? collapsedNavWidth : navWidth,
          ...(!hidden && navCollapsed && navHover ? { boxShadow: 10 } : {}),
          borderRight: navigationBorderWidth === 0 ? 0 : `${navigationBorderWidth}px solid ${theme.palette.divider}`,
          ...userNavMenuPaperStyle,
        },
        ...navMenuProps?.PaperProps,
      }}
      sx={{
        width: navCollapsed ? collapsedNavWidth : navWidth,
        ...userNavMenuStyle,
      }}
      {...userNavMenuProps}
    >
      {children}
    </SwipeableDrawer>
  );
};

export default Drawer;
