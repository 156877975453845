import { ReactNode } from "react";
/* eslint-disable no-nested-ternary */
// ** MUI Imports
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid, { GridProps } from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CustomRadioIconsData } from "components";

// ** Types
type CustomCardsProps = {
  name: string;
  icon?: ReactNode;
  gridProps: GridProps;
  data: CustomRadioIconsData;
};

export default function EmptyTableCards(props: CustomCardsProps) {
  // ** Props
  const { data, gridProps } = props;

  const { title, content } = data;
  const theme = useTheme();

  return (
    <Grid item {...gridProps} display={data.hide ? "none" : "grid"}>
      <Box
        onClick={data.action}
        sx={{
          px: 4,
          mx: 4,
          py: 12,
          height: "100%",
          display: "flex",

          borderRadius: 1,
          cursor: "pointer",
          position: "relative",
          alignItems: "center",
          flexDirection: "column",
          border: (themeItem) =>
            `1px solid ${themeItem.palette.mode === "dark" ? themeItem.palette.secondary.light : themeItem.palette.divider}}`,
          "&:hover": { borderColor: `${theme.palette.primary.main}` },
        }}
      >
        {data?.icon ? <>{data.icon}</> : null}
        {title ? (
          typeof title === "string" ? (
            <Typography variant="h3" sx={{ fontWeight: 700, ...(content ? { mb: 4, mt: 4 } : { my: "auto" }) }}>
              {title}
            </Typography>
          ) : (
            title
          )
        ) : null}
        {content ? (
          typeof content === "string" ? (
            <Typography variant="h5" sx={{ my: "auto", textAlign: "center" }}>
              {content}
            </Typography>
          ) : (
            content
          )
        ) : null}
      </Box>
    </Grid>
  );
}
