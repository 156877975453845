// ** MUI Imports
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
// ** Components Imports
import { StepperCustomDot, StepperWrapper } from "components";
//** Hooks */
import { useRequestContext } from "pages/requestAccess/context";
import { useTranslation } from "react-i18next";
import { Box, Theme, useMediaQuery } from "@mui/material";

export default function StepperRequest() {
  //** Context */
  const { activeStep } = useRequestContext();
  //** Hooks */
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  //** Render array */
  const steps = [
    {
      title: `${t("STEPPER-1")}`,
    },
    {
      title: `${t("STEPPER-2")}`,
    },
    {
      title: `${t("STEPPER-3")}`,
    },
    {
      title: `${t("STEPPER-4")}`,
    },
  ];

  if (underMd) {
    return (
      <Box sx={{ width: "100%" }} mb={8}>
        <Stepper
          activeStep={activeStep}
          sx={{
            "& .MuiStepIcon-root": {
              width: 25,
              height: 25,
            },
          }}
        >
          {steps.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel />
              </Step>
            );
          })}
        </Stepper>
      </Box>
    );
  }
  return (
    <StepperWrapper mb={8} mt={2}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          return (
            <Step key={index}>
              <StepLabel StepIconComponent={StepperCustomDot}>
                <div className="step-label">
                  <Typography className="step-number">{`0${index + 1}`}</Typography>
                  <div>
                    <Typography className="h2">{step.title}</Typography>
                    {/* <Typography className="step-subtitle">{step.subtitle}</Typography> */}
                  </div>
                </div>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </StepperWrapper>
  );
}
