import { ReactNode } from "react";
// ** MUI Imports
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import { Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

interface IModalProps {
  open: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
  children: ReactNode;
}
export default function ExportModal({ open, handleConfirm, handleClose, children }: IModalProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="loc-export"
      sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: 570 } }}
      aria-describedby="loc-export-description"
    >
      <DialogTitle id="loc-export" sx={{ fontSize: "2rem !important", px: 8, pt: 4 }}>
        <Typography variant="h3">{t("LOCATION-EXPORT-TITLE")}</Typography>
      </DialogTitle>

      <DialogContent sx={{ px: 8 }}>{children}</DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", mb: 4, px: 8 }}>
        <Button variant="text" color="primary" size="large" sx={{ mr: 1 }} onClick={handleClose}>
          {t("BUTTON-CANCEL")}
        </Button>
        <Button
          variant="text"
          sx={{ mr: 1 }}
          size="large"
          onClick={() => {
            handleConfirm();
            handleClose();
          }}
        >
          {t("BUTTON-EXPORT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
