/* eslint-disable consistent-return */
import { Box, Divider } from "@mui/material";
import { UnderMdGuard } from "guards";

import { ContactInputs, OpenHours } from "pages/locations/components";

export default function ContactStep() {
  return (
    <Box>
      <ContactInputs />
      <UnderMdGuard>
        <OpenHours />
      </UnderMdGuard>
      <Divider />
    </Box>
  );
}
