//**MUI IMPORTS */
import { Box, Skeleton, Typography } from "@mui/material";
//**COMPONENTSIMPORTS */
import { FileUploader, Input } from "components";
//** HOOKS */
import { useInputValueContext, useRoleGuard } from "hooks";
import { useOrganizationContext } from "pages/organizations/context";
import { useTranslation } from "react-i18next";
//** Model */
import { Roles } from "models";

export default function BasicInformation({ createView }: { createView?: boolean }) {
  //** States and Context */
  const { name, description, logo, logoText, alertRequiredFields, setOrgHasChanges } = useOrganizationContext();
  const { setName, setDescription, setLogo, setLogoText } = useOrganizationContext();
  //** HOOKS */
  const { t } = useTranslation();
  const nameInput = useInputValueContext("", setName);
  const descriptionInput = useInputValueContext("", setDescription);
  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager]);

  const isRequiedFieldEmpty = createView ? alertRequiredFields : true;

  const changeControllerFn = () => setOrgHasChanges(true);

  return (
    <Box>
      <Typography variant="h3" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
        {t("ORGANIZATION-BASIC-INFORMATION")}
      </Typography>

      <Box my={8}>
        <Input
          name="org-name"
          value={name}
          onChange={nameInput.onChange}
          label={`${t("INPUT-ORG-NAME")}*`}
          fullWidth
          disabled={!authUpdateOrganization}
          error={name?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
          changeControllerFn={changeControllerFn}
        />
      </Box>

      <Input
        name="org-description"
        multiline
        value={description}
        onChange={descriptionInput.onChange}
        label={`${t("INPUT-DESCRIPTION")}`}
        fullWidth
        rows={4}
        disabled={!authUpdateOrganization}
        maxLength={500}
        changeControllerFn={changeControllerFn}
      />

      {!logo.length && logoText ? (
        <Skeleton height={130} animation="wave" />
      ) : (
        <Box my={6}>
          <FileUploader
            files={logo}
            setFiles={setLogo}
            label="Logo*"
            disabled={!authUpdateOrganization}
            removeFunction={() => {
              setLogoText("");
            }}
            required={logo?.length === 0 && isRequiedFieldEmpty}
            changeControllerFn={changeControllerFn}
          />
        </Box>
      )}
    </Box>
  );
}
