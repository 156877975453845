import { Backdrop, Box } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

const Puller = styled("div")(() => ({
  width: 40,
  height: 6,
  backgroundColor: grey[500],
  borderRadius: 3,
  position: "absolute",
  top: 10,
  left: "calc(50% - 25px)",
}));

interface DrawerMobileProps {
  children: React.ReactNode;
  drawerBleeding?: number;
  closeDrawer?: () => void;
}
export default function DrawerActionMobile({ children, drawerBleeding, closeDrawer }: DrawerMobileProps) {
  const drawerBleedingProp = drawerBleeding || 56;

  return (
    <>
      <Backdrop open sx={{ zIndex: 2000 }} onClick={closeDrawer} />
      <SwipeableDrawer
        sx={{
          "& .MuiDrawer-paper": {
            height: `calc(90% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
          zIndex: 2001,
        }}
        onClose={() => {}}
        onOpen={() => {}}
        anchor="bottom"
        open={false}
        swipeAreaWidth={drawerBleedingProp}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -drawerBleedingProp,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            visibility: "visible",
            right: 0,
            left: 0,
            height: drawerBleedingProp,
            backgroundColor: "background.paper",
          }}
        >
          <Puller />
          <Box mt={10}>{children}</Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
}
