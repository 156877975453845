import { useEffect, useRef, useState } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import settings from "config/settings.json";
import MarkerInfo from "./MarkerInfo";

export interface ICoordinates {
  lat: number | undefined;
  lng: number | undefined;
  id?: string;
  name?: string;
  status?: boolean;
  address?: string;
}

interface IMaps {
  positions: ICoordinates[];
  multiple?: boolean;
  mapHeight?: string;
  options?: google.maps.MapOptions | undefined;
  singleDetail?: boolean;
  zoom?: number;
}
function Maps({ positions, multiple, mapHeight, options, singleDetail, zoom }: IMaps) {
  const mapRef = useRef<google.maps.Map>();

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  const [libraries] = useState<any>(["geometry", "drawing", "places"]);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { id } = useParams();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${settings.GOOGLE_API_KEY}`,
    libraries,
  });

  const mapStyles = {
    minHeight: mapHeight || "60vh",
    height: "100%",
    width: "100%",
  };
  const center = {
    lat: 0,
    lng: 0,
  };

  const sigleMarkerPosition = {
    lat: positions[0]?.lat ? +positions[0].lat : 0,
    lng: positions[0]?.lng ? +positions[0].lng : 0,
  };

  const centerOnSigleMarker = positions?.length > 0 ? sigleMarkerPosition : center;
  const responsiveOptions: google.maps.MapOptions | undefined = underMd
    ? {
        zoomControlOptions: {
          position: 4,
        },
        streetViewControlOptions: {
          position: 4,
        },
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: 10.0,
        },
      }
    : {};

  const responsiveMap = underMd
    ? {
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }
    : {};
  const defaultZoom = zoom || 19;
  useEffect(() => {
    if (positions.length > 1000) return;

    if (mapRef.current && positions.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      positions.forEach((position) => {
        bounds.extend(new google.maps.LatLng(position?.lat || 0, position?.lng || 0));
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [positions]);

  return (
    <Box>
      {isLoaded && (
        <GoogleMap
          onLoad={handleLoad}
          mapContainerStyle={mapStyles}
          zoom={positions?.length > 0 ? defaultZoom : 2}
          center={multiple ? center : centerOnSigleMarker}
          options={{
            ...options,
            ...responsiveMap,
            maxZoom: 21,
            restriction: {
              latLngBounds: {
                north: 90,
                south: -90,
                east: 180,
                west: -180,
              },
              ...responsiveOptions,
              strictBounds: true,
            },
          }}
        >
          {positions?.map((item: any) => {
            const numericCoordinates = {
              lat: +item.lat,
              lng: +item.lng,
            };
            if (singleDetail && item.lat && item.lng) {
              return (
                <MarkerInfo
                  position={{ lat: +item.lat, lng: +item.lng }}
                  name={item.name}
                  address1={item.address}
                  isVisible={item.status}
                  id={id || ""}
                />
              );
            }
            return <Marker position={numericCoordinates} />;
          })}
        </GoogleMap>
      )}
    </Box>
  );
}

export default Maps;
