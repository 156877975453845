//** MUI Imports */
import { Box, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
//** Components Imports */
import { Button } from "components";
//** Hooks */
import { useTranslation } from "react-i18next";
//** Icons */

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import { PublicRoutes } from "models";

interface IProps {
  onClickPrev: Function;
  onClickNext: Function;
  disabledPrev?: boolean;
  disabledNext?: boolean;
}

export default function StepperButtons(props: IProps) {
  const { onClickPrev, onClickNext, disabledPrev, disabledNext } = props;
  //** Hooks */
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <>
      <Stack justifyContent="space-between" direction="row" mt={12}>
        <Button
          name="org-previous"
          label={`${underMd ? t("BUTTON-BACK") : t("BUTTON-PREVIOUS")}`}
          onClick={onClickPrev}
          startIcon={underMd ? undefined : <ArrowBackIosIcon sx={{ fontSize: "14px !important" }} />}
          variant="outlined"
          disabled={disabledPrev}
          size={underMd ? "large" : "medium"}
        />
        <Button
          name="org-next"
          label={`${t("BUTTON-NEXT")}`}
          onClick={onClickNext}
          endIcon={underMd ? undefined : <ArrowForwardIosIcon sx={{ fontSize: "14px !important" }} />}
          disabled={disabledNext}
          size={underMd ? "large" : "medium"}
        />
      </Stack>
      <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center" }} mt={8}>
        <Typography sx={{ mr: 2, color: "text.secondary" }}>{t("REQUEST-FOOTER-TEST-1")}</Typography>
        <Link to={`/${PublicRoutes.LOGIN}`} replace>
          <Typography sx={{ color: "primary.main", textDecoration: "none" }}>{t("REQUEST-FOOTER-TEST-2")}</Typography>
        </Link>
      </Box>
    </>
  );
}
