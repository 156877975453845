import { Box, Theme, Typography, useTheme } from "@mui/material";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";

interface OrgAboutMobileProps {
  address: string;
  phone: string;
  email: string;
  region: string;
}
export default function OrgAboutMobile({ address, phone, email, region }: OrgAboutMobileProps) {
  const theme: Theme = useTheme();
  return (
    <Box>
      <Box display="flex" alignItems="center" px={8} gap={4} py={6} borderBottom={`2px solid ${theme.palette.divider}`}>
        <PlaceOutlinedIcon sx={{ fontSize: 24 }} color="primary" />
        <Typography variant="h5">{address}</Typography>
      </Box>
      <Box display="flex" alignItems="center" px={8} gap={4} py={6} borderBottom={`2px solid ${theme.palette.divider}`}>
        <LocalPhoneOutlinedIcon sx={{ fontSize: 24 }} color="primary" />
        <Typography variant="h5">{phone}</Typography>
      </Box>
      <Box display="flex" alignItems="center" px={8} gap={4} py={6} borderBottom={`2px solid ${theme.palette.divider}`}>
        <EmailOutlinedIcon sx={{ fontSize: 24 }} color="primary" />
        <Typography variant="h5">{email}</Typography>
      </Box>
      <Box display="flex" alignItems="center" px={8} gap={4} py={6} borderBottom={`2px solid ${theme.palette.divider}`}>
        <PublicOutlinedIcon sx={{ fontSize: 24 }} color="primary" />
        <Typography variant="h5">{region}</Typography>
      </Box>
    </Box>
  );
}
