import { useEffect } from "react";
//**Context */
import { Box, Fade } from "@mui/material";
import { useLocationContext } from "pages/locations/context";
import { ButtonUpdateLocation } from "pages/locations/pages";
import { AddressStep, AditionalStep, BasicInformationLocationStep, ContactStep } from "../../organism";
import ModalGoBackLocation from "../../molecules/ModalGoBackLocation/ModalGoBackLocation";

export default function StepsLocation({ action, loading }: { action: "create" | "update"; loading?: boolean }) {
  const { setActiveStep, setTabValue } = useLocationContext();

  //** Hooks */
  useEffect(() => {
    //** For creation */
    setActiveStep(0);
    //** For Update */
    setTabValue("1");
  }, []);
  if (action === "update") {
    return (
      <Fade in>
        <div>
          <BasicInformationLocationStep />
          <Box mt={6}>
            <AddressStep loading={loading} />
          </Box>
          <ContactStep />
          <Box mt={8}>
            <AditionalStep hiddenButtons />
          </Box>
          <Box mb={8}>
            <ButtonUpdateLocation />
          </Box>
          <ModalGoBackLocation />
        </div>
      </Fade>
    );
  }
  return (
    <Fade in>
      <div>
        <BasicInformationLocationStep create />
        <Box mt={6}>
          <AddressStep />
        </Box>
        <ContactStep />
        <Box mt={8}>
          <AditionalStep hiddenButtons />
        </Box>
        <ModalGoBackLocation />
      </div>
    </Fade>
  );
}
