//** Componets */
import { Box, IconButton, Typography } from "@mui/material";
//** Icon */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function GoBackBreadcrumbMobile({
  label,
  onClick,
  description,
}: {
  label: string;
  onClick: () => void;
  description: string;
}) {
  return (
    <Box mb={8}>
      <Box display="flex" alignItems="center" mt={8} mb={4} sx={{ cursor: "pointer" }} onClick={onClick}>
        <IconButton sx={{ mr: 2 }}>
          <ArrowBackIcon sx={{ fontSize: 24 }} />
        </IconButton>
        <Typography variant="h4">{label}</Typography>
      </Box>
      <Typography variant="h5" color="text.disabled">
        {description}
      </Typography>
    </Box>
  );
}
