/* eslint-disable @typescript-eslint/ban-ts-comment */
// ** MUI Imports
import MuiChip from "@mui/material/Chip";
// ** Third Party Imports
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from "clsx";
// ** Hooks Imports
import useBgColor, { UseBgColorType } from "core/hooks/useBgColor";
import { Theme, useMediaQuery } from "@mui/material";
// ** Types
import { CustomChipProps } from "./types";

function Chip(props: CustomChipProps) {
  // ** Props
  const { sx, skin, color = "secondary", rounded, variant, fontSize, customColors, onClick } = props;
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const defaultFontSize = fontSize || (underMd ? 12 : 14);
  const fontSizeSx = { ...sx, fontSize: defaultFontSize };

  // ** Hook
  const bgColors = useBgColor();

  const colors: UseBgColorType = {
    primary: { ...bgColors.primaryLight },
    secondary: { ...bgColors.secondaryLight },
    success: { ...bgColors.successLight },
    error: { ...bgColors.errorLight },
    warning: { ...bgColors.warningLight },
    info: { ...bgColors.infoLight },
  };
  const customColorsChip: UseBgColorType = {
    admin: { ...bgColors.admin },
    multiOrganization: { ...bgColors.multiOrganization },
    organization: { ...bgColors.organization },
    location: { ...bgColors.location },
  };

  const propsToPass = { ...props };

  propsToPass.rounded = undefined;

  const defaultColors = Object?.assign(colors[color] || {}, fontSizeSx || {});
  const customColor = Object?.assign(customColorsChip[customColors || ""] || {}, fontSizeSx || {});

  // customColors is the main prop color, then if there are no customColors, it will use the default color
  const defaultStyles = color ? defaultColors : fontSizeSx;
  const chipColorAndStyle = customColors ? customColor : defaultStyles;

  return (
    <MuiChip
      {...propsToPass}
      variant={variant || "filled"}
      className={clsx({
        "MuiChip-rounded": rounded,
        "MuiChip-light": skin === "light",
      })}
      onClick={onClick || (() => {})}
      //@ts-ignore
      sx={chipColorAndStyle}
    />
  );
}

export default Chip;
