// ** MUI Import

import Box, { BoxProps } from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled, Typography, TypographyProps } from "@mui/material";
import themeConfig from "config/themeConfig";

const HeaderTitle = styled(Typography)<TypographyProps>({
  fontWeight: 700,
  lineHeight: 1.2,
  transition: "opacity .25s ease-in-out, margin .25s ease-in-out",
});

function FallbackSpinner({ sx }: { sx?: BoxProps["sx"] }) {
  return (
    <Box
      sx={{
        height: "100dvh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        ...sx,
      }}
    >
      <HeaderTitle variant="h4">{themeConfig.templateName}</HeaderTitle>
      <CircularProgress disableShrink sx={{ mt: 6 }} />
    </Box>
  );
}

export default FallbackSpinner;
