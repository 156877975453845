import { useEffect } from "react";
import { Box, Theme, Typography, useTheme } from "@mui/material";
import { useLocationContext } from "pages/locations/context";
import { useTranslation } from "react-i18next";
import { useGetSearchData } from "pages/locations/pages/locationsList/hooks";
//**Icons */
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";

export default function LocAboutMobile() {
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  const { address1, region, website, purpose, category, applicableBrand, openingHours } = useLocationContext();

  const { purposeOptions, getPurposes } = useGetSearchData();

  const purposeCodes = purpose.map((item) => item.code);
  const locPurposes = purposeOptions.filter((item) => purposeCodes?.includes(`${item.code}`));

  useEffect(() => {
    getPurposes();
  }, []);

  return (
    <Box>
      <Box display="flex" alignItems="center" px={8} gap={4} py={6} borderBottom={`2px solid ${theme.palette.divider}`}>
        <PlaceOutlinedIcon sx={{ fontSize: 24 }} color="primary" />
        <Box>
          <Typography variant="h5" fontWeight="700">
            {t("MOBILE-LOC-DASH-ITEM1")}
          </Typography>
          <Typography variant="h5" color="text.disbled">
            {address1}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" px={8} gap={4} py={6} borderBottom={`2px solid ${theme.palette.divider}`}>
        <PublicOutlinedIcon sx={{ fontSize: 24 }} color="primary" />
        <Box>
          <Typography variant="h5" fontWeight="700">
            {t("MOBILE-LOC-DASH-ITEM2")}
          </Typography>
          <Typography variant="h5" color="text.disbled">
            {region.label}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" px={8} gap={4} py={6} borderBottom={`2px solid ${theme.palette.divider}`}>
        <LanguageOutlinedIcon sx={{ fontSize: 24 }} color="primary" />
        <Box>
          <Typography variant="h5" fontWeight="700">
            {t("MOBILE-LOC-DASH-ITEM3")}
          </Typography>
          <Typography variant="h5" color="text.disbled">
            {website}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" px={8} gap={4} py={6} borderBottom={`2px solid ${theme.palette.divider}`}>
        <StoreMallDirectoryOutlinedIcon sx={{ fontSize: 24 }} color="primary" />
        <Box>
          <Typography variant="h5" fontWeight="700">
            {t("MOBILE-LOC-DASH-ITEM4")}
          </Typography>
          <Typography variant="h5" color="text.disbled">
            {locPurposes.map((item, i) => `${item.label}${i < locPurposes.length - 1 ? " - " : ""}`)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" px={8} gap={4} py={6} borderBottom={`2px solid ${theme.palette.divider}`}>
        <InventoryOutlinedIcon sx={{ fontSize: 24 }} color="primary" />
        <Box>
          <Typography variant="h5" fontWeight="700">
            {t("MOBILE-LOC-DASH-ITEM5")}
          </Typography>
          <Typography variant="h5" color="text.disbled">
            {category.map((item, i) => `${item}${i < category.length - 1 ? " - " : ""}`)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" px={8} gap={4} py={6} borderBottom={`2px solid ${theme.palette.divider}`}>
        <BusinessCenterOutlinedIcon sx={{ fontSize: 24 }} color="primary" />
        <Box>
          <Typography variant="h5" fontWeight="700">
            {t("MOBILE-LOC-DASH-ITEM6")}
          </Typography>
          <Typography variant="h5" color="text.disbled">
            {applicableBrand.map((item, i) => `${item.label}${i < applicableBrand.length - 1 ? " - " : ""}`)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" px={8} gap={4} py={6} borderBottom={`2px solid ${theme.palette.divider}`}>
        <WatchLaterOutlinedIcon sx={{ fontSize: 24 }} color="primary" />
        <Box width="100%">
          <Typography variant="h5" fontWeight="700">
            {t("MOBILE-LOC-DASH-ITEM7")}
          </Typography>
          <Box width="100%">
            {openingHours.map((openHour) => {
              return (
                <Box gap={8} display="flex" mb={8} mt={8}>
                  <Box width="40%">
                    <Typography variant="h5">{openHour.label}</Typography>
                  </Box>
                  <Typography variant="h5">{`${openHour.opening} - ${openHour.closing}`}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
