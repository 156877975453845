/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTables } from "hooks";

import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import CheckIcon from "@mui/icons-material/Check";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Chip } from "components";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";
import useUserTableItems from "pages/users/hooks/useUserTableItems";
import { IUsersListDOM } from "pages/users/models";
import { useUsersListContext } from "pages/users/pages/usersList/context";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getColorChipByRole } from "utilities/getColorChips";
import { UserFastActions } from "../../molecules/UserFastActions";

export default function TableResponsiveRow({
  data,
  onSearch,
  fromDashboard,
}: {
  data: IUsersListDOM;
  onSearch?: () => void;
  fromDashboard?: boolean;
}) {
  //**Vars */

  const { usersArray, selected } = useUsersListContext();

  //**Hooks */
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSelected } = useUsersListContext();
  const { handleClickTableCheckbox, isSelected, handleClickTableCheckboxMobile } = useTables(setSelected, selected, usersArray);
  const { handleTouchStart, handleTouchMove, handleTouchEnd, hoverItem, setHoverItem } = useUserTableItems({
    handleClickTableCheckboxMobile,
  });
  //** Media querys */
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const smAbove = useMediaQuery("(min-width:900px)");
  const over1000px = useMediaQuery("(min-width:1000px)");

  const isItemSelected = isSelected(data.id);
  const isItemHover = data.id === hoverItem;

  const selectedOnMobile = isItemSelected && underMd;
  const checkboxActive = isItemHover || isItemSelected || selected.length > 0;

  //Table logic to navigate
  const handleNavigate = (id: string) => {
    navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: false });
  };
  const theme = useTheme();

  function stringAvatarOrgList(name: string, lastName: string, isActivate: boolean) {
    const successColor = theme.palette.customColors.successBackground;
    const defaultColor = theme.palette.customColors.secondaryBackground;

    return {
      sx: {
        bgcolor: isActivate ? successColor : defaultColor,
        color: isActivate ? theme.palette.common.black : theme.palette.common.white,
        fontWeight: 600,
        width: underMd ? 40 : 50,
        height: underMd ? 40 : 50,
      },
      children: `${name?.split(" ")[0][0]?.toUpperCase() || ""}${lastName?.split(" ")[0][0]?.toUpperCase() || ""}`,
    };
  }

  return (
    <ListItem sx={{ px: underMd ? 0 : 4, py: 0 }}>
      <ListItemButton
        role={undefined}
        onClick={() => {
          handleNavigate(data.id);
        }}
        sx={{
          pl: 6,
          pr: 4,
          backgroundColor: "background.paper",
          borderBottom: (theme: Theme) => `${theme.palette.divider} 1px solid`,

          display: "flex",
          alignItems: "center",
          height: !underMd ? "100px" : "auto",
          py: underMd ? 4 : 0,
        }}
        onMouseEnter={smAbove ? () => setHoverItem(data.id) : undefined}
        onMouseLeave={smAbove ? () => setHoverItem("") : undefined}
        onTouchStart={smAbove ? undefined : () => handleTouchStart(data.id, !data.isSuspended)}
        onTouchEnd={smAbove ? undefined : () => handleTouchEnd()}
        onTouchMove={smAbove ? undefined : () => handleTouchMove()}
      >
        {(selectedOnMobile || !underMd || selected.length > 0) && (
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
            <ListItemIcon onClick={(e) => e.stopPropagation()} sx={{ mr: 0 }}>
              {underMd && !fromDashboard ? (
                <>
                  {isItemSelected && (
                    <Checkbox
                      onClick={(event) => handleClickTableCheckbox(event, data.id, !data.isSuspended)}
                      sx={
                        checkboxActive
                          ? {
                              "& .MuiSvgIcon-root": { fontSize: 40 },
                              visibility: "visible",
                              display: "block",
                              p: 0,
                              pl: 4,
                              pr: 4,
                              mr: 4,
                              ml: 4,
                            }
                          : { visibility: "hidden", display: "none" }
                      }
                      checked={isItemSelected}
                      checkedIcon={
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ backgroundColor: theme.palette.primary.dark, borderRadius: "50%", width: 40, height: 40 }}
                        >
                          <CheckIcon sx={{ color: theme.palette.background.default, fontSize: "24px !important" }} />
                        </Box>
                      }
                    />
                  )}
                </>
              ) : (
                <Checkbox
                  onClick={(event) => handleClickTableCheckbox(event, data.id, !data.isSuspended)}
                  sx={
                    checkboxActive
                      ? { "& .MuiSvgIcon-root": { fontSize: 24 }, visibility: "visible", display: "block", p: 0, pl: 4 }
                      : { visibility: "hidden", display: "none" }
                  }
                  checked={isItemSelected}
                />
              )}
            </ListItemIcon>
          </RoleGuard>
        )}
        {selectedOnMobile && !fromDashboard ? null : (
          <ListItemAvatar sx={{ mr: 8 }}>
            <Box display="flex">
              <Box display="flex" onClick={(e) => e.stopPropagation()} pl={8}>
                <Avatar
                  onClick={(e) => {
                    e.stopPropagation();
                    if (underMd) handleClickTableCheckbox(e, data.id, !data.isSuspended);
                  }}
                  {...stringAvatarOrgList(`${data.firstName}`, `${data.lastName}`, !data.isSuspended)}
                />
              </Box>
            </Box>
          </ListItemAvatar>
        )}
        <Box width="100%" pr={7.2} display="flex" justifyContent="space-between">
          <Box width="100%" display="flex" alignItems="center">
            <Box display="flex" alignItems="flex-start" flexDirection="column" width={over1000px ? "30%" : "100%"}>
              <Typography
                variant={underMd ? "h5" : "h4"}
                fontWeight={600}
                mb={2}
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "40vw",
                }}
                alignItems="center"
              >
                {`${data.firstName} ${data.lastName}`.toUpperCase()}
              </Typography>
              {(underMd || !fromDashboard) && !fromDashboard && (
                <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} mt={2}>
                  <Chip
                    label={data.roleData[0].name || ""}
                    size="small"
                    fontSize={underMd ? 10 : 12}
                    customColors={getColorChipByRole(data.roleData[0].name)}
                  />
                </Box>
              )}

              {underMd && fromDashboard && (
                <>
                  <Typography noWrap variant="h6" sx={{ color: "text.disabled" }}>
                    {data.roleData[0].name}
                  </Typography>
                  <Typography noWrap variant="h6" sx={{ color: "text.disabled" }}>
                    {data.username}
                  </Typography>
                  <Typography noWrap variant="h6" sx={{ color: "text.disabled" }}>
                    {data.phone}
                  </Typography>
                </>
              )}
            </Box>
            {over1000px && (
              <Box width="30%" display="flex" alignItems="center">
                <IconButton
                  onClick={(e: any) => {
                    e.stopPropagation();
                    window.location.href = `tel:${data.phone}`;
                  }}
                  sx={{ color: (theme: Theme) => theme.palette.text.primary, py: 2, px: 2 }}
                >
                  <CallOutlinedIcon sx={{ fontSize: 22, pb: 0 }} />
                </IconButton>
                <Typography variant="h5" color="text.secondary">
                  {data.phone}
                </Typography>
              </Box>
            )}
            {over1000px && (
              <Box width="30%" display="flex" alignItems="center">
                <IconButton
                  onClick={(e: any) => {
                    e.stopPropagation();
                    window.location.href = `mailto:${data.username}`;
                  }}
                  sx={{ color: (theme: Theme) => theme.palette.text.primary, py: 2, px: 2 }}
                >
                  <EmailOutlinedIcon sx={{ fontSize: 22 }} />
                </IconButton>
                <Typography variant="h5" color="text.secondary">
                  {data.username}
                </Typography>
              </Box>
            )}
          </Box>

          <Box display="flex" alignItems="center" width={underMd ? 50 : 140} justifyContent="flex-end">
            <Box
              sx={{
                display: "flex",
                alignItems: underMd ? "center" : "flex-end",
                justifyContent: "flex-start",
              }}
              gap={2}
            >
              {(fromDashboard || !underMd) && (
                <>
                  {data.isSuspended ? (
                    <Chip size="small" label={t("OPTION-SELECT-1")} color="secondary" sx={{ fontSize: "1.4rem" }} />
                  ) : (
                    <Chip size="small" label={t("OPTION-SELECT-2")} color="success" sx={{ fontSize: "1.4rem" }} />
                  )}
                </>
              )}

              <UserFastActions
                id={data.id}
                isActive={!data.isSuspended}
                handleMouseLeave={() => setHoverItem("")}
                onSearch={() => {
                  if (onSearch) onSearch();
                }}
              />
            </Box>
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
