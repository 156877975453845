import { Dispatch, SetStateAction } from "react";

export default function useTables(
  setSelected: Dispatch<SetStateAction<{ id: string; active: boolean }[]>>,
  selected: { id: string; active: boolean }[],
  listArray: any[],
) {
  const handleClickTableCheckbox = (event: React.MouseEvent<unknown> | null, id: string, isActive: boolean) => {
    const selectedIndex = selected.findIndex((item) => item.id === `${id}`);
    let newSelected: { id: string; active: boolean }[] = [...selected];

    if (selectedIndex === -1) newSelected = [...selected, { id, active: isActive }];
    else newSelected.splice(selectedIndex, 1);

    setSelected(newSelected);
  };
  const handleClickTableCheckboxMobile = (event: React.MouseEvent<unknown> | null, id: string, isActive: boolean) => {
    const selectedIndex = selected.findIndex((item) => item.id === `${id}`);
    let newSelected: { id: string; active: boolean }[] = [...selected];

    if (selectedIndex === -1) {
      newSelected = [...selected, { id, active: isActive }];
    } else newSelected.splice(selectedIndex, 1);

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = listArray.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string) => selected.findIndex((item) => item.id === `${id}`) !== -1;

  return { handleClickTableCheckbox, isSelected, handleSelectAllClick, handleClickTableCheckboxMobile };
}
