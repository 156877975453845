import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function StepperLocForm({ activeStep, onlyTitle }: { activeStep: number; onlyTitle?: boolean }) {
  const { t } = useTranslation();

  const stepTitles = [
    t("ORGANIZATION-BASIC-INFORMATION"),
    t("LOCATION-FACILITY-INFO"),
    t("LOCATION-INFO"),
    t("GLOBAL-TITLE-BUSINESS-INFO"),
  ];

  return (
    <Box sx={{ width: "100%" }} mb={8}>
      {!onlyTitle && (
        <Stepper
          activeStep={activeStep}
          sx={{
            "& .MuiStepIcon-root": {
              width: 25,
              height: 25,
            },
          }}
        >
          {Array.from({ length: 4 }, (_, index) => (
            <Step key={`step-org-${index}`}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      )}

      <Typography variant="h2" display="flex" alignItems="center" fontWeight={700} mt={4}>
        {`0${activeStep + 1}`}
        <Typography variant="h3" ml={4}>
          {stepTitles[activeStep]}
        </Typography>
      </Typography>
    </Box>
  );
}
