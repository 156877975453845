const breakpoints = () => ({
  values: {
    xs: 350,
    sm: 600,
    md: 800,
    lg: 1200,
    xl: 1536,
  },
});

export default breakpoints;
