//**Context */
import { useLocationContext } from "pages/locations/context";
//** External components */
import { Box, Skeleton, Theme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

//** Components */
import { GoBackArrowMobile, GoBackBreadcrumb } from "components";
import UserDashboardView from "pages/users/components/organism/UserDashboardView";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//** Hooks */
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRoleGuard } from "hooks";
import { PrivateRoutes, Roles } from "models";
import { RoleGuard } from "guards";
import { useLocation } from "../../../hooks";
import LocationCard from "../../molecules/LocationCard/LocationCard";
import { LocationMap } from "../../molecules";
import LocDashboardMobile from "../../mobile/LocDashboardMobile";

export default function LocationDashboard() {
  //**Context */
  const { organization, name, userCount } = useLocationContext();

  //**Hooks */
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const orgAndAdminRoles = [
    Roles.superAdmin,
    Roles.multiOrgManager,
    Roles.multiOrgViewer,
    Roles.organizationManager,
    Roles.organizationViewer,
  ];
  const isAdminOrOrgRelated = useRoleGuard(orgAndAdminRoles);

  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { locationId } = userState;

  const locId = params.id || `${locationId}`;

  //** Data get information */
  const { loading } = useLocation(locId);

  const redirectOrgQuery = `orgId=${organization.id}&orgName=${organization.label}`;
  const redirectLocQuery = `&locId=${locId}&locName=${name}`;
  const redirectUrl = `/${PrivateRoutes.USERS}/${PrivateRoutes.CREATE}?${redirectOrgQuery}${redirectLocQuery}`;

  if (underMd) {
    return (
      <>
        <GoBackArrowMobile
          onClick={() => {
            navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${organization.id}`);
          }}
        />
        <LocDashboardMobile redirectUrlUser={redirectUrl} loading={loading} />;
      </>
    );
  }

  return (
    <Box mb={underMd ? 32 : 0}>
      <RoleGuard authorizedRoles={orgAndAdminRoles}>
        <GoBackBreadcrumb
          label={t("GO-BACK-ORGANIZATION")}
          onClick={() => {
            navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${organization.id}`);
          }}
        />
      </RoleGuard>

      <Grid container spacing={12} mx={0} mt={isAdminOrOrgRelated ? -6 : 12}>
        <Grid container md={12} lg={3}>
          <Grid xs={12} md={6} lg={12}>
            {loading || !name ? <Skeleton height="380px" variant="rounded" /> : <LocationCard locationId={locId} />}
          </Grid>
          <Grid xs={12} md={6} lg={12}>
            <UserDashboardView
              redirectUrl={redirectUrl}
              loadingExternalData={loading || !name}
              locationId={locId}
              userOrgCounter={userCount}
            />
          </Grid>
        </Grid>
        <Grid md={12} lg={9} height="100%">
          <LocationMap loading={loading} />
        </Grid>
      </Grid>
    </Box>
  );
}
