import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import Color from "color-thief-react";
import { useState } from "react";
import OrgEditMobile from "./OrgEditMobile";
import OrgTabsMenuMobile from "./OrgTabsMenuMobile";

interface OrgDrawerHeaderProps {
  logo: string;
  name: string;
  url: string;
  isActive: boolean;
  handleUpdateStatus?: () => void;
}

export default function OrgDrawerHeader({ logo, name, url, isActive, handleUpdateStatus }: OrgDrawerHeaderProps) {
  const [load, setLoad] = useState(true);

  return (
    <Box mx={2}>
      <Box display="flex" gap={4} justifyContent="space-between" mb={4}>
        <Box display="flex" gap={4} width="50%">
          <Color src={logo} crossOrigin="anonymous" format="hex">
            {({ data, loading }) => {
              if (loading) {
                return (
                  <Skeleton sx={{ display: load ? "block" : "none", ml: 4 }} animation="wave" variant="circular" width={40} height={40} />
                );
              }
              return (
                <Avatar
                  sx={{
                    display: load ? "none" : "block",
                    ml: 4,
                    width: 40,
                    height: 40,
                    backgroundColor: data || "background.default",
                    "& .MuiAvatar-img": { objectFit: "contain !important" },
                  }}
                  src={typeof logo === "string" ? logo : ""}
                  alt={name}
                  onLoad={(e) => {
                    if (e.type === "load") {
                      setLoad(false);
                    }
                  }}
                />
              );
            }}
          </Color>
          <Box>
            <Box maxHeight={40} overflow="hidden">
              <Typography variant="h3" fontWeight="700">
                {name}
              </Typography>
            </Box>
            <Box maxHeight={40} overflow="hidden">
              <Typography variant="h5" color="textSecondary" sx={{ cursor: "pointer", maxWidth: "80%", overflow: "hidden" }}>
                {url}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <OrgEditMobile isActive={!isActive} handleUpdateStatus={handleUpdateStatus} />
        </Box>
      </Box>
      <OrgTabsMenuMobile />
    </Box>
  );
}
