//** MUI Imports */
import { Box, Fade, Theme, useMediaQuery } from "@mui/material";
//** Error Management */
import { ErrorBoundary } from "utilities";
//** Context */
import { LocSpeedDial } from "components/responsiveComponents";
import { LocationListContextProvider } from "./context";

import LocationsTable from "./components/molecules/locationsTable/LocationsTable";

export default function LocationsList({
  organizationId,
  fromDashboard,
  orgName,
}: {
  organizationId?: string;
  fromDashboard?: boolean;
  orgName?: string;
}) {
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <LocationListContextProvider>
      <ErrorBoundary fallBackComponent={<>Something went wrong</>}>
        <Fade in timeout={500}>
          <Box m={!underMd ? 4 : -2}>
            <LocationsTable organizationId={organizationId} fromDashboard={fromDashboard} orgName={orgName} />
          </Box>
        </Fade>
        {underMd && !fromDashboard && <LocSpeedDial />}
      </ErrorBoundary>
    </LocationListContextProvider>
  );
}
