import { Box, Checkbox, IconButton, Stack, Typography } from "@mui/material";
import { RoleGuard } from "guards";
import { Roles } from "models";
import { LocBulkActions } from "pages/locations/components";

import AddIcon from "@mui/icons-material/Add";
import { FilterChips, Input, InputApiCall, Select, TableSearch } from "components";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useGetRoles } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUsersListContext } from "../../context";
import useUsersSearch from "../../hooks/useUsersSearch";

export default function AppListHeaderMobileUser({
  onSearch,
  redirectUrl,
  loadingExternalData,
}: {
  onSearch: Function;
  redirectUrl: string;
  loadingExternalData: boolean;
}) {
  const { locationHasFilter } = useListAndSearchContext();
  const { usersArray, setSelected, selected } = useUsersListContext();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getRoles, loading } = useGetRoles();

  const firstNameSearchFn = useUsersSearch().userFirstNameSearch;
  const lastNameSearchFn = useUsersSearch().userLastNameSearch;
  const emailSearchFn = useUsersSearch().userEmailSearch;
  const statusUserSearch = useUsersSearch().userStatusSearch;
  const roleSearch = useUsersSearch().userRoleSearch;
  const organizationUserSearch = useUsersSearch().userOrganizationSearch;

  const numSelected = selected.length;
  const rowCount = usersArray.length;
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = usersArray.map((n) => ({ id: n.id, active: !n.isSuspended }));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  //**Chips */
  const filterChips = [
    {
      key: "first_name",
      label: "INPUT-FIRST-NAME",
      data: firstNameSearchFn.value,
    },
    {
      key: "last_name",
      label: "INPUT-LAST-NAME",
      data: lastNameSearchFn.value,
    },
    {
      key: "email",
      label: "INPUT-EMAIL",
      data: emailSearchFn.value,
    },
    {
      key: "role",
      label: "INPUT-ROLE",
      data: roleSearch.valueLabel,
    },
    {
      key: "status",
      label: "INPUT-STATUS",
      data: statusUserSearch.value,
    },
    {
      key: "organization",
      label: "GLOBAL-TITLE-ORGNAIZATION",
      data: organizationUserSearch.valueLabel,
    },
  ];

  //Filter Chips functions
  const handleDelete = (filterName: "first_name" | "last_name" | "email" | "role" | "status" | "organization") => {
    if (filterName === "first_name") firstNameSearchFn.clearByKey();
    if (filterName === "last_name") lastNameSearchFn.clearByKey();
    if (filterName === "email") emailSearchFn.clearByKey();
    if (filterName === "role") roleSearch.clearByKey();
    if (filterName === "status") statusUserSearch.clearByKey();
    if (filterName === "organization") organizationUserSearch.clearByKey();
    onSearch();
  };
  const handleDeleteAll = () => {
    firstNameSearchFn.clearByKey();
    lastNameSearchFn.clearByKey();
    emailSearchFn.clearByKey();
    roleSearch.clearByKey();
    statusUserSearch.clearByKey();
    organizationUserSearch.clearByKey();
    onSearch();
  };

  const optionsStatus = [t("OPTION-SELECT-1"), t("OPTION-SELECT-2")];

  return (
    <Box mx={4} mt={1}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager]}>
            <Box mt={2} ml={-4}>
              <Box display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
                <Typography variant="h5">{t("BUTTON-SELECT-ALL")}</Typography>
              </Box>
              {selected.length > 0 && (
                <Box>
                  <LocBulkActions onSearch={onSearch} hasSelectedItems={selected.length > 0} />
                </Box>
              )}
            </Box>
          </RoleGuard>
        </Box>

        <Box display="flex">
          <TableSearch
            isDashboard
            noOptionsComponent={
              <Box>
                <Stack direction="column" spacing={4} mb={4}>
                  <Input name="usr-first-name-search" {...firstNameSearchFn} label={`${t("INPUT-FIRST-NAME")}`} fullWidth />
                  <Input name="usr-last-name-search" {...lastNameSearchFn} label={`${t("INPUT-LAST-NAME")}`} fullWidth />
                </Stack>
                <Box mb={4}>
                  <Input name="usr-email-search" {...emailSearchFn} label={`${t("INPUT-EMAIL")}`} fullWidth />
                </Box>
                <Stack direction="column" spacing={4} mb={4}>
                  <Select
                    name="usr-status-search"
                    value={statusUserSearch.value || ""}
                    label={`${t("INPUT-STATUS")}`}
                    setValue={statusUserSearch.onChange}
                    options={optionsStatus}
                  />
                  <InputApiCall
                    name="usr-role-search"
                    externalValue={{ label: roleSearch.valueLabel || "", id: roleSearch.valueId || "" }}
                    setPropValue={roleSearch.onChange}
                    apiCallFunction={getRoles}
                    loading={loading}
                    label={`${t("INPUT-ROLE")}`}
                  />
                </Stack>
              </Box>
            }
            onReset={handleDeleteAll}
            onSubmit={(e: any) => {
              e.preventDefault();
              onSearch();
            }}
          />
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
            <IconButton
              onClick={() => {
                navigate(`${redirectUrl}`);
              }}
              disabled={loadingExternalData}
              color="inherit"
              sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" }, padding: "6px" }}
            >
              <AddIcon sx={{ fontSize: 25 }} />
            </IconButton>
          </RoleGuard>
        </Box>
      </Box>
      {!!locationHasFilter && (
        <Stack direction="row" spacing={4} alignItems="center" mt={2} mb={2} pb={0}>
          <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
        </Stack>
      )}
    </Box>
  );
}
