import { useEffect } from "react";
//**MUI IMPORTS */
import { Box, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
//**COMPONENTS IMPORTS */
import { Input, InputApiCallInfiniteScroll } from "components";
//** HOOKS */
import { useGetOrganizationsInfiniteScroll, useGetReduxUser, useInputValueContext, useRoleGuard } from "hooks";
import { useLocationContext } from "pages/locations/context";
import { useTranslation } from "react-i18next";
import useQuery from "utilities/getLocation";
//** Model */
import { Roles } from "models";
import { HqSwitch } from "../HqSwitch";

export default function BasicInformationLocation({ create }: { create?: boolean }) {
  //** States and Context */
  const { name, organization, view, alertRequiredFields, isHQLocation, setLocHasChanges } = useLocationContext();
  const { setName, setOrganization } = useLocationContext();
  const { descriptionSBDUS } = useLocationContext();
  const { setDescriptionSBDUS } = useLocationContext();

  const { getOrganizationsInfiniteScroll, hasNextPage, orgData, loading, orgPage, setOrgPage, setOrgData } =
    useGetOrganizationsInfiniteScroll();

  const queryUrl = useQuery();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  //** HOOKS */
  const { t } = useTranslation();
  const nameInput = useInputValueContext("", setName);
  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.organizationManager, Roles.locationManager, Roles.multiOrgManager]);

  const authUpdateOrganizationField = useRoleGuard([Roles.superAdmin, Roles.multiOrgManager]);
  const descriptionSBDUSInput = useInputValueContext("", setDescriptionSBDUS);

  const { userState } = useGetReduxUser();

  const autocompleteOrgWithQuery = queryUrl.get("orgId") && create ? { id: queryUrl.get("orgId"), label: queryUrl.get("orgName") } : null;
  const autocompleteOrgWithUserOrg = !authUpdateOrganizationField
    ? { id: userState.organization?.id, label: userState.organization?.name }
    : null;

  const autocompleteOrg = autocompleteOrgWithQuery || autocompleteOrgWithUserOrg || organization;
  useEffect(() => {
    if (organization.id) return;

    setOrganization(autocompleteOrg as any);
  }, [autocompleteOrgWithQuery, autocompleteOrgWithUserOrg]);

  //**Required inputs management */
  const isRequiedFieldEmpty = view === "create" ? alertRequiredFields[0] : true;
  const changeControllerFn = () => setLocHasChanges(true);

  return (
    <Box>
      <Box>
        <Typography variant={underMd ? "h4" : "h3"} sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
          {t("ORGANIZATION-BASIC-INFORMATION")}
        </Typography>
        <HqSwitch />
      </Box>
      <Stack direction="column" spacing={4} mb={6} mt={8}>
        <InputApiCallInfiniteScroll
          disabled={!authUpdateOrganizationField || view === "create" ? !!queryUrl.get("orgId") : isHQLocation}
          initialValue={autocompleteOrg}
          name="loc-organization"
          setPropValue={setOrganization}
          changeWithInputText
          apiCallFunction={getOrganizationsInfiniteScroll}
          loading={loading}
          label={`${t("GLOBAL-TITLE-ORGNAIZATION")} *`}
          hasNextPage={hasNextPage}
          page={orgPage}
          options={orgData}
          setPage={setOrgPage}
          setOptions={setOrgData}
          requiredFields={(organization?.label?.length === 0 || organization === null) && isRequiedFieldEmpty}
          alertLabel={`${t("SELECT-ORG-INPUT")}`}
          createWithInitialValue
          changeControllerFn={changeControllerFn}
        />
        <Input
          name="loc-name"
          value={name}
          onChange={nameInput.onChange}
          label={`${t("INPUT-LOCATION-NAME")}*`}
          fullWidth
          disabled={!authUpdateOrganization}
          error={name?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
          changeControllerFn={changeControllerFn}
        />
      </Stack>

      <Input
        name="description"
        multiline
        value={descriptionSBDUS}
        onChange={descriptionSBDUSInput.onChange}
        label={`${t("INPUT-DESCRIPTION")}`}
        fullWidth
        rows={4}
        disabled={!authUpdateOrganization}
        changeControllerFn={changeControllerFn}
      />
    </Box>
  );
}
