import AddIcon from "@mui/icons-material/Add";
import { styled, Typography } from "@mui/material";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";
import { useNavigate } from "react-router-dom";

export default function OrgSpeedDial({ absolute }: { absolute?: boolean }) {
  const OrgSpeedDialStyled = styled("div")(({ theme }) => ({
    zIndex: 11,
    position: absolute ? "absolute" : "fixed",
    right: theme.spacing(6), //6
    bottom: theme.spacing(10), //10
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  }));

  const navigate = useNavigate();
  return (
    <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager]}>
      <OrgSpeedDialStyled
        onClick={() => {
          navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.CREATE}`, { replace: false });
        }}
        sx={{
          backgroundColor: "primary.main",
          borderRadius: "8px",
          width: 127,
          height: 56,
        }}
      >
        <AddIcon sx={{ fontSize: 28, fontWeight: 700, color: "#FFF" }} />

        <Typography variant="h4" color="#FFF" ml={4}>
          Create
        </Typography>
      </OrgSpeedDialStyled>
    </RoleGuard>
  );
}
