import { Box } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useState } from "react";

const Puller = styled("div")(() => ({
  width: 40,
  height: 6,
  backgroundColor: grey[500],
  borderRadius: 3,
  position: "absolute",
  top: 10,
  left: "calc(50% - 25px)",
}));

interface DrawerMobileProps {
  children: React.ReactNode;
  header: React.ReactNode;
  drawerBleeding?: number;
}
export default function DrawerMobile({ children, header, drawerBleeding }: DrawerMobileProps) {
  const [open, setOpen] = useState(false);
  const drawerBleedingProp = drawerBleeding || 56;

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <SwipeableDrawer
      sx={{
        "& .MuiDrawer-paper": {
          height: `calc(90% - ${drawerBleeding}px)`,
          overflow: "visible",
        },
      }}
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      swipeAreaWidth={drawerBleedingProp}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -drawerBleedingProp,
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          visibility: "visible",
          right: 0,
          left: 0,
          height: drawerBleedingProp,
          backgroundColor: "background.paper",
        }}
      >
        <Puller />
        <Box mt={10}>{header}</Box>
      </Box>
      <Box sx={{ px: 2, pb: 2, height: "100%", overflow: "auto" }}>{children}</Box>
    </SwipeableDrawer>
  );
}
