import { useEffect, useState } from "react";
//** MUI Imports */
import { Box, Card, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { GoBackBreadcrumb, GoBackBreadcrumbMobile, SectionsTitles } from "components";
import { StepsLocation } from "pages/locations/components";
//** Hooks */
import { useTranslation } from "react-i18next";
import useQuery from "utilities/getLocation";
import { useNavigate } from "react-router-dom";
import { useGetReduxUser } from "hooks";
//** Utils */
//** Context */
import { useLocationContext } from "pages/locations/context";
import { PrivateRoutes } from "models";
import { useGlobalContext } from "context/globalContext";
import StepperLocForm from "pages/locations/components/mobile/StepperLocForm";
import StepsLocCreateMobile from "pages/locations/components/mobile/StepsLocCreateMobile";

export default function LocationsCreate() {
  //**Hooks */
  const navigate = useNavigate();
  const queryUrl = useQuery();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { userState } = useGetReduxUser();
  const { organization } = userState;

  //** Context*/
  const { setView, clearBasicInformation, locHasChanges, setGoBackModalLoc } = useLocationContext();
  const { clearBasicInformation: clearBasicInformationGlobal } = useGlobalContext();
  //** Hooks */
  const { t } = useTranslation();
  useEffect(() => {
    setView("create");
    clearBasicInformation();
    clearBasicInformationGlobal();
  }, []);

  const hasOrgId = !!queryUrl.get("orgId") || organization?.id;
  const [activeStep, setActiveStep] = useState(0);
  const returnToOrgUrl = `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${organization?.id || queryUrl.get("orgId")}`;
  const returnToLocatUrl = `/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.LIST}`;
  const url = hasOrgId ? returnToOrgUrl : returnToLocatUrl;

  if (underMd) {
    return (
      <Box sx={{ backgroundColor: "background.paper" }}>
        <Box mb={12} pb={16} mt={-8}>
          <Box mx={8}>
            <GoBackBreadcrumbMobile
              label={t("LOCATION-DETAIL-TITLE")}
              description={t("ORGANIZATION-CREATE-SUBTITLE")}
              onClick={() => {
                if (locHasChanges) return setGoBackModalLoc({ open: true, urlToGo: `${url}` });
                return navigate(`${url}`);
              }}
            />
            <StepperLocForm activeStep={activeStep} />
          </Box>

          <StepsLocCreateMobile activeStep={activeStep} setActiveStep={setActiveStep} urlToGo={url} />
        </Box>
      </Box>
    );
  }

  return (
    <Box mx={4} mb={underMd ? 12 : 0}>
      <GoBackBreadcrumb
        label={hasOrgId ? t("GO-BACK-ORGANIZATION") : t("GO-BACK-ORGANIZATION-DASHBOARD")}
        onClick={() => {
          if (locHasChanges) return setGoBackModalLoc({ open: true, urlToGo: `${url}` });
          return navigate(`${url}`);
        }}
      />
      <Card sx={{ py: 8, px: 6 }}>
        <SectionsTitles h2 title={`${t("GLOBAL-TITLE-NEW-LOCATION")}`} subTitle={`${t("LOCATION-CREATE-SUBTITLE")}`} />
        <StepsLocation action="create" />
      </Card>
    </Box>
  );
}
