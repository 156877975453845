import { Box, Fade, Theme, useMediaQuery } from "@mui/material";
import { UsersSpeedDial } from "components/responsiveComponents";
import { RoleGuard } from "guards";
import { Roles } from "models";

import UsersTable from "./components/molecules/UsersTable/UsersTable";
import { UsersListContextProvider } from "./context";

export default function UsersList({
  organizationId,
  fromDashboard,
  locationId,
  redirectUrl,
}: {
  organizationId?: string;
  fromDashboard?: boolean;
  locationId?: string;
  redirectUrl?: string;
}) {
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <UsersListContextProvider>
      <Box m={!underMd ? 4 : -2} mt={underMd ? 0 : 14}>
        <Fade in timeout={500}>
          <Box>
            <UsersTable
              organizationId={organizationId || ""}
              fromDashboard={fromDashboard}
              locationId={locationId || ""}
              redirectUrl={redirectUrl}
            />
          </Box>
        </Fade>

        {!fromDashboard && (
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationManager, Roles.locationManager, Roles.multiOrgManager]}>
            <Fade in={underMd}>
              <Box position="relative">
                <UsersSpeedDial organizationId={organizationId || ""} embebed={fromDashboard} locationId={locationId || ""} />
              </Box>
            </Fade>
          </RoleGuard>
        )}
      </Box>
    </UsersListContextProvider>
  );
}
