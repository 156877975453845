//** MUI imports */
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

//** Images */
import logo from "assets/Logo_login.png";

// ** Configs
import themeConfig from "config/themeConfig";
import { useTranslation } from "react-i18next";

export default function LoginHeader() {
  /** Hooks */
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ mb: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img src={logo} alt="logo" />
      </Box>
      <Box sx={{ mb: 12 }}>
        <Typography variant="h2" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
          {`${t("LOGIN-TITLE")} ${themeConfig.templateName}! 👋🏻`}
        </Typography>
        <Typography variant="h5">{t("LOGIN-SUB-TITLE")}</Typography>
      </Box>
    </>
  );
}
