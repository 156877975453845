/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { Box, IconButton, Switch, useTheme } from "@mui/material";
import { ModalConfirm } from "components";
import { useFetchAndLoad, useToastMui } from "hooks";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { PrivateRoutes } from "models";
import { useDeleteLocations } from "pages/locations/hooks";
import { IBulkLocation } from "pages/locations/models";
import { bulkLocationAPI } from "pages/locations/services";
import { useLocationContext } from "pages/locations/context";

interface LocEditMobileProps {
  isVisible: boolean;
  handleUpdateStatus?: () => void;
}

export default function LocEditMobile({ isVisible, handleUpdateStatus }: LocEditMobileProps) {
  const { isHQLocation } = useLocationContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

  const params = useParams();
  const { t } = useTranslation();
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { deleteLocations } = useDeleteLocations();
  const theme = useTheme();

  const navigate = useNavigate();

  const handleChangeActive = async () => {
    try {
      const body: IBulkLocation = { is_visible: isVisible, item_id: [params.id || ""] };
      setOpenModal(false);

      await callEndpoint(bulkLocationAPI({ body }), () => handleCreateToast(t("ALERT-ERROR-UPDATE"), "error"));
      handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
      handleUpdateStatus && handleUpdateStatus();
    } catch (error) {
      console.log(error);
    }
  };
  // Handle  dialog
  const handleClickOpen = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenDelete = () => setOpenModalDelete(true);
  const handleCloseDelete = () => setOpenModalDelete(false);

  const handleEdit = () => {
    navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.EDIT_NAV}/${params.id}`);
  };

  const iconColor = theme.palette.mode === "dark" ? "white" : "black";

  return (
    <Box sx={{ zIndex: 10000000 }}>
      <Box display="flex" onClick={(e) => e.stopPropagation()}>
        <Switch checked={isVisible} onChange={handleClickOpen} color="success" sx={{ pointerEvents: "all" }} size="medium" />
        <IconButton sx={{ backgroundColor: "background.default", pointerEvents: "all" }} onClick={handleOpenDelete}>
          <DeleteOutlinedIcon sx={{ fontSize: 20, color: iconColor }} />
        </IconButton>
        <IconButton sx={{ backgroundColor: "background.default", pointerEvents: "all", ml: 4, mr: 2 }} onClick={handleEdit}>
          <EditOutlinedIcon sx={{ fontSize: 20, color: iconColor }} />
        </IconButton>
      </Box>
      <ModalConfirm
        open={openModal}
        title={isVisible ? "Make visible 1 locations?" : "Make visible 1 locations?"}
        description={isVisible ? t("BULK-LOC-VISIBLE") : t("BULK-LOC-HIDE")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleCloseModal}
        handleConfirm={handleChangeActive}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
      <ModalConfirm
        open={openModalDelete}
        title="Delete 1 locations?"
        description={isHQLocation ? t("LOCATIONS-DELETE-MODAL-ERROR-HQ") : t("LOCATIONS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleCloseDelete}
        handleConfirm={() => {
          deleteLocations([params.id || ""]);
          setOpenModalDelete(false);
          navigate(`${PrivateRoutes.LOCATIONS}/${PrivateRoutes.LIST}`);
        }}
        disableAccept={isHQLocation}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </Box>
  );
}
