import AddIcon from "@mui/icons-material/Add";
import { Box, styled, Typography } from "@mui/material";
import DrawerActionMobile from "components/molecules/DrawerMobile/DrawerActionMobile";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";
import { ModalUserExport } from "pages/organizations/components/molecules/ModalUserExport";
import useExportUsers from "pages/users/hooks/useExportUsers";
import { UserImportOnHeader } from "pages/users/pages/usersList/components/atoms";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

export default function UsersSpeedDial({
  absolute,
  embebed,
  organizationId,
  locationId,
}: {
  absolute?: boolean;
  embebed?: boolean;
  organizationId?: string;
  locationId?: string;
}) {
  // ** init trigger

  const [openDrawer, setOpenDrawer] = useState(false);

  const { t } = useTranslation();
  const { setOpenImportModalUser, openExport, setOpenexport } = useListAndSearchContext();
  const [callStatus, setCallStatus] = useState<"success" | "error" | null>(null);

  const navigate = useNavigate();

  const { exportUsers } = useExportUsers({
    organizationId: organizationId || "",
    locationId: locationId || "",
    embebed,
  });

  const handleCloseExport = () => setOpenexport(false);
  const handleOpenExport = () => setOpenexport(true);

  const authorizedRoles = [Roles.superAdmin, Roles.organizationManager, Roles.locationManager, Roles.multiOrgManager];

  const UserSpeedDialStyled = styled("div")(({ theme }) => ({
    zIndex: 11,
    position: absolute ? "absolute" : "fixed",
    right: theme.spacing(6), //6
    bottom: theme.spacing(10), //10
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  }));

  return (
    <>
      <div>
        <RoleGuard authorizedRoles={authorizedRoles}>
          <UserSpeedDialStyled
            onClick={() => {
              setOpenDrawer(true);
            }}
            sx={{
              backgroundColor: "primary.main",
              borderRadius: "8px",
              width: 60,
              height: 56,
            }}
          >
            <AddIcon sx={{ fontSize: 28, fontWeight: 700, color: "#FFF" }} />
          </UserSpeedDialStyled>
        </RoleGuard>
      </div>
      <UserImportOnHeader callStatus={callStatus} setCallStatus={setCallStatus} />
      <ModalUserExport open={openExport} handleExport={exportUsers} handleClose={handleCloseExport} />
      {openDrawer && (
        <DrawerActionMobile
          drawerBleeding={130}
          closeDrawer={() => {
            setOpenDrawer(false);
          }}
        >
          <Box sx={{ pointerEvents: "all" }} px={16}>
            <Box
              display="flex"
              alignContent="center"
              gap={4}
              my={6}
              onClick={() => {
                setOpenDrawer(false);
                navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.CREATE}`, { replace: false });
              }}
            >
              <ModeEditOutlineOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography variant="h5">{t("BUTTON-CREATE")}</Typography>
            </Box>
            <Box
              display="flex"
              alignContent="center"
              gap={4}
              mb={6}
              onClick={() => {
                setOpenDrawer(false);
                setOpenImportModalUser(true);
                setCallStatus(null);
              }}
            >
              <FileUploadOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography variant="h5">{t("BUTTON-IMPORT")}</Typography>
            </Box>
            <Box
              display="flex"
              alignContent="center"
              gap={4}
              onClick={() => {
                setOpenDrawer(false);
                handleOpenExport();
              }}
            >
              <FileDownloadOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography variant="h5">{t("BUTTON-EXPORT")}</Typography>
            </Box>
          </Box>
        </DrawerActionMobile>
      )}
    </>
  );
}
