import { useEffect } from "react";
//** MUI Imports */
import { Box, Fade, Theme, useMediaQuery } from "@mui/material";
//** Error Management */
import { ErrorBoundary } from "utilities";
//** Context */
import { OrgSpeedDial } from "components/responsiveComponents/OrgSpeedDial";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import ErrorView from "pages/ErrorView";
import { useOrganizationContext } from "pages/organizations/context";
import OrganizationsTable from "./components/molecules/OrganizationsTable/OrganizationsTable";
import { OrganizationListContextProvider } from "./context";

export default function OrganizationList() {
  const { cleatBasicInformation } = useOrganizationContext();

  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { setOrganizationsPage } = useListAndSearchContext();
  useEffect(() => {
    //Clear context variables
    cleatBasicInformation();
    if (underMd) {
      setOrganizationsPage(0);
    }
  }, []);

  return (
    <OrganizationListContextProvider>
      <ErrorBoundary fallBackComponent={<ErrorView />}>
        <Fade in timeout={500}>
          <Box m={!underMd ? 4 : 2}>
            <OrganizationsTable />
          </Box>
        </Fade>
        {underMd && <OrgSpeedDial />}
      </ErrorBoundary>
    </OrganizationListContextProvider>
  );
}
