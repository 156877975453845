/* eslint-disable no-unused-expressions */
import { Box, Stack, Theme, useMediaQuery } from "@mui/material";
import { Button, ModalConfirm } from "components";
//** Hooks */
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
//** Context */
import { useUserContext } from "pages/users/context";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//** Models */
import { PrivateRoutes, Roles } from "models";
import { accessConfirmationAPI } from "pages/users/pages/usersRequest/services";
import { useFetchAndLoad, useToastMui } from "hooks";
import { capitalize } from "utilities";
import { useState } from "react";
import { useUpdateUsers } from "../UpdateUserButtons/hooks";

export default function InviteButtons() {
  //**CONTEXT */
  const { firstName, lastName, email, phone, SBDEmail, role, organization, location } = useUserContext();
  const { isValidEmail, isValidSBDEmail } = useUserContext();

  //**Redux  */
  const userState = useSelector((store: AppStore) => store.user);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { roles } = userState;

  //**Hooks */
  const navigate = useNavigate();
  const { t } = useTranslation();
  const callRequest = useFetchAndLoad();
  const { id } = useParams();
  const { handleCreateToast } = useToastMui();
  const { updateUser, loading } = useUpdateUsers(true);

  const [showModal, setShowModal] = useState(false);

  const isBasicInfoEmpty = !firstName || !lastName || !email || !phone || !SBDEmail;
  const orgNotFilled = Array.isArray(organization) ? organization.length === 0 : organization?.label === "";
  const needsOrganization = userState.roles === Roles.superAdmin && orgNotFilled;

  const isOrganizationNeeded =
    role.label === Roles.organizationViewer || role.label === Roles.organizationManager || role.label === Roles.multiOrgManager
      ? orgNotFilled || !role?.label
      : false;
  const haveRoleIfNonAdmin = isOrganizationNeeded && !role?.label;
  const userLocReqFields =
    (role.label === Roles.locationViewer || role.label === Roles.locationManager) && (needsOrganization || !location?.label);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const hadleAcceptInvite = async () => {
    try {
      //The user can update the data before accepting the invitation
      await updateUser(`${id}`, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await callRequest.callEndpoint(accessConfirmationAPI(`${id}`, true), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-ACCEPT")} : ${capitalize(error?.response?.data?.message)} `, "error"),
      );

      handleCreateToast(t("ALERT-SUCCESS-ACCEPT"), "success");

      navigate(`/${PrivateRoutes.USERS_REQUEST}/${PrivateRoutes.LIST}`, { replace: false });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleDecline = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await callRequest.callEndpoint(accessConfirmationAPI(`${id}`, false), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-ACCEPT")} : ${capitalize(error?.response?.data?.message)} `, "error"),
      );
      handleCreateToast(t("ALERT-SUCCESS-ACCEPT"), "success");

      navigate(`/${PrivateRoutes.USERS_REQUEST}/${PrivateRoutes.LIST}`, { replace: false });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const mobileStyles = {
    position: "fixed",
    bottom: 0,
    width: "100%",
    px: 8,
    py: 4,
    backgroundColor: "background.paper",
    zIndex: 10000,
  };

  return (
    <Box mt={8} sx={underMd ? { ...mobileStyles } : {}}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button
          name="user-decline"
          onClick={handleOpenModal}
          label={t("BUTTON-DECLINE")}
          size="large"
          variant="outlined"
          disabled={roles === Roles.multiOrgViewer || roles === Roles.organizationViewer}
        />
        <Stack direction="row" gap={4}>
          <Button
            name="user-invite"
            onClick={hadleAcceptInvite}
            label={t("BUTTON-ACCEPT-INVITE")}
            size="large"
            disabled={
              isBasicInfoEmpty ||
              !role?.label ||
              orgNotFilled ||
              !isValidEmail ||
              !isValidSBDEmail ||
              loading ||
              callRequest.loading ||
              roles === Roles.multiOrgViewer ||
              roles === Roles.organizationViewer ||
              userLocReqFields ||
              haveRoleIfNonAdmin
            }
          />
        </Stack>
      </Box>
      <ModalConfirm
        open={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleDecline}
        title={`${t("REQUEST-MODAL-DECLINE-TITLE")}`}
        description={t("REQUEST-MODAL-DECLINE-DESCRIPTION")}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </Box>
  );
}
