import { Box, Skeleton } from "@mui/material";
import { DrawerMobile, Maps } from "components";
import { useLocationContext } from "pages/locations";
import useGetLocationsDashboard from "pages/locations/pages/locationsList/hooks/useLocationsDashboard";
import { UsersList } from "pages/users";
import { useParams } from "react-router-dom";

import LocAboutMobile from "./LocAboutMobile";
import LocDrawerHeader from "./LocDrawerHeader";

interface IProps {
  loading: boolean;
  redirectUrlUser: string;
}
export default function LocDashboardMobile({ loading, redirectUrlUser }: IProps) {
  const { id } = useParams();

  useGetLocationsDashboard(`${id || ""}`);
  const { latitude, longitude, name, locMobileTab } = useLocationContext();

  return (
    <div>
      <Maps
        zoom={12}
        positions={[{ lat: +latitude, lng: +longitude }]}
        mapHeight="calc(100dvh - 180px)"
        options={{ mapTypeControlOptions: { position: 3 } }}
      />
      <DrawerMobile
        drawerBleeding={170}
        header={<>{loading || !name ? <Skeleton variant="rounded" height={130} sx={{ mx: 4 }} /> : <LocDrawerHeader />}</>}
      >
        <Box>
          {locMobileTab === 0 && <LocAboutMobile />}
          {locMobileTab === 1 && <UsersList locationId={id} fromDashboard redirectUrl={redirectUrlUser} />}
        </Box>
      </DrawerMobile>
    </div>
  );
}
