import { Box, FormControlLabel, Stack, Switch, Theme, Typography, useMediaQuery } from "@mui/material";
import { FileUploaderLocations, FileUploadMain, Input } from "components";
import { useInputValueContext, useRoleGuard } from "hooks";
import { Roles } from "models";
import { useLocationContext } from "pages/locations/context";
import { useTranslation } from "react-i18next";

export default function FacilityInformationInputs() {
  //**Context */
  const { amountOfEmployees, facilitySquareFootage, yearFounded, sustainable } = useLocationContext();
  const { setAmountOfEmployees, setFacilitySquareFootage, setYearFounded, setSustainable, setLocHasChanges } = useLocationContext();

  const { setMainPictureText, setAdditionalPicturesText } = useLocationContext();
  const { mainLocationImage, locationImagesAdditional } = useLocationContext();
  const { mainPictureText, additionalPicturesText } = useLocationContext();
  const { setMainLocationImage, setLocationImagesAdditional } = useLocationContext();

  const amountOfEmployeesInput = useInputValueContext("", setAmountOfEmployees);
  const facilitySquareFootageInput = useInputValueContext("", setFacilitySquareFootage);
  const matches = useMediaQuery("(max-width:600px)");
  const matchesTablet = useMediaQuery("(max-width:800px)");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { t } = useTranslation();
  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.organizationManager, Roles.locationManager, Roles.multiOrgManager]);

  const handleChangeYear = (event: any) => {
    const data = event.target.value;
    setYearFounded(data.replace(/[^0-9]/g, ""));
  };

  const changeControllerFn = () => setLocHasChanges(true);

  return (
    <Box>
      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={6} mx={-2}>
        <Input
          name="amount-of-employees"
          value={amountOfEmployees}
          onChange={amountOfEmployeesInput.onChange}
          label={`${t("INPUT-AMOUNT-OF-EMPLOYEES")}`}
          type="number"
          fullWidth
          disabled={!authUpdateOrganization}
          changeControllerFn={changeControllerFn}
        />
        <Input
          name="facilitySquareFootage"
          type="number"
          value={facilitySquareFootage}
          onChange={facilitySquareFootageInput.onChange}
          label={`${t("INPUT-FACILITY-SQUARE")}`}
          fullWidth
          disabled={!authUpdateOrganization}
          changeControllerFn={changeControllerFn}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6} mx={-2}>
        <Input
          maxLength={4}
          name="year-founded"
          value={yearFounded === 0 ? "" : yearFounded}
          onChange={handleChangeYear}
          label={`${t("INPUT-YEAR-FOUNDED")}`}
          fullWidth
          disabled={!authUpdateOrganization}
          changeControllerFn={changeControllerFn}
        />
        <Box width="100%" />
      </Stack>
      <Box mt={-6} mb={6}>
        <Typography variant={underMd ? "h4" : "h2"} sx={{ fontWeight: 600, letterSpacing: "0.18px" }} mt={12}>
          {t("SUSTAINABLE-TITLE")}
        </Typography>
        <Typography variant="h5" mt={4} mb={8}>
          {t("SUSTAINABLE-DESCRIPTION")}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={sustainable}
              onChange={() => {
                setSustainable((prev) => !prev);
                changeControllerFn();
              }}
            />
          }
          label={t("INPUT-SUSTAINABLE")}
          disabled={!authUpdateOrganization}
        />
      </Box>
      <Typography variant={underMd ? "h4" : "h2"} sx={{ fontWeight: 500, letterSpacing: "0.18px" }}>
        Location Images
      </Typography>
      <Box display="flex" gap={4} flexWrap="wrap" mt={8} justifyContent={!matchesTablet ? "" : "center"}>
        <FileUploadMain
          loading={!mainLocationImage.length && !!mainPictureText}
          files={mainLocationImage}
          setFiles={setMainLocationImage}
          label={t("FILE-MAIN")}
          removeFunction={() => {
            setMainPictureText("");
          }}
          disabled={!authUpdateOrganization}
          changeControllerFn={changeControllerFn}
        />
        <FileUploaderLocations
          setFileText={setAdditionalPicturesText}
          files={locationImagesAdditional}
          setFiles={setLocationImagesAdditional}
          label={t("FILE-ADD-MORE")}
          fileText={additionalPicturesText}
          disabled={mainLocationImage.length === 0 || !authUpdateOrganization}
          changeControllerFn={changeControllerFn}
        />
      </Box>
    </Box>
  );
}
