/* eslint-disable no-extra-boolean-cast */
import { Box, IconButton, Theme, Typography, useMediaQuery } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import MyLocationTwoToneIcon from "@mui/icons-material/MyLocationTwoTone";
import { useTranslation } from "react-i18next";

interface ISelectMarker {
  internalCoordinates: [number | undefined, number | undefined] | [];
  isMoving: boolean;
  address: string;
  loadingMapInfo: boolean;
  onSearchPlaceByMapId?: () => void;
}
export default function SelectMarker({ internalCoordinates, isMoving, address, loadingMapInfo, onSearchPlaceByMapId }: ISelectMarker) {
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        zIndex: 1000,
        position: "absolute",
        top: "calc(50% - 110px)",
        left: "calc(50% - 22px)",
        fontSize: "24px",
      }}
    >
      <Box
        height={50}
        maxWidth={350}
        sx={{
          bgcolor: "#FFF",
          p: underMd ? 2 : 4,
          visibility: !!(internalCoordinates[0] && internalCoordinates[1]) ? "visible" : "hidden",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          transform: "translate(-40%, 0) ",
        }}
      >
        <Box>
          <Box display="flex">
            <Box mr={4} height={underMd ? 35 : 30} borderRadius="50%" width={30} display="flex" alignItems="center" justifyContent="center">
              <IconButton onClick={onSearchPlaceByMapId}>
                <MyLocationTwoToneIcon
                  sx={{
                    fontSize: 28,
                    fill: "#000",
                    opacity: 0.9,
                    transition: "transform 0.3s ease-in-out",
                    transform: "scale(1)",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                />
              </IconButton>
            </Box>
            <Box>
              {loadingMapInfo ? (
                <Box className="dot-container" height="18px">
                  <div className="dot" />
                  <div className="dot" />
                  <div className="dot" />
                </Box>
              ) : (
                <Typography
                  variant={underMd ? "h5" : "h3"}
                  color="black"
                  fontWeight="700"
                  sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: 280 }}
                >
                  {address}
                </Typography>
              )}

              <Typography variant={underMd ? "h5" : "h4"} color="primary" sx={{ cursor: "pointer", mt: 1 }} onClick={onSearchPlaceByMapId}>
                {t("GLOBAL-SELECT-ADDESS-MAP")}
              </Typography>
            </Box>
            {/* <Typography variant="h3" color="black">{`${internalCoordinates[0]} - ${internalCoordinates[1]}`}</Typography> */}
          </Box>
        </Box>
      </Box>

      <Box>
        <PlaceIcon
          sx={{
            fontSize: 45,
            fill: "#FF0000",
            stroke: "#FFFFFF",
            strokeWidth: 1,
            transition: "transform 0.3s ease-in-out", // Animación de transición
            transform: isMoving ? "translate(0, 10%) scale(1.1)" : "translate(0, 30%) scale(1)", // Escala del icono
          }}
        />
      </Box>

      <Box width="10px" height="10px" borderRadius="50%" bgcolor="#FF0000" border="1px solid #FFF" ml="18px" />
    </Box>
  );
}
