/* eslint-disable no-confusing-arrow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
//**Hooks */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//**Components */
import { Stack, Theme } from "@mui/material";
import { Chip } from "components/molecules";
import { ChipStatus, Methods } from "constants/globalConstants";

import { useLocationListContext } from "pages/locations";

export default function ClaimChips() {
  const { setLocationStatus, locationStatus } = useListAndSearchContext();
  const { setToggleClaim } = useLocationListContext();

  const handleClick = (value: ChipStatus) => {
    //** Set visibility to search */
    let methodStatus: Methods | null = null;
    if (value === ChipStatus.CLAIMED) methodStatus = Methods.CLAIMED;
    if (value === ChipStatus.UNCLAIMED) methodStatus = Methods.UNCLAIMED;
    if (value === ChipStatus.CREATED) methodStatus = Methods.MANUAL;
    setLocationStatus(methodStatus);
    setToggleClaim((prev: boolean | null) => (prev === null ? true : !prev));
    //** Set selected chip */
    setLocationStatus(methodStatus);
  };

  return (
    <Stack direction="row" spacing={4} alignItems="center" ml={4} my={6}>
      <Chip
        key="chip-unclaim"
        label={ChipStatus.UNCLAIMED}
        onClick={() => handleClick(ChipStatus.UNCLAIMED)}
        sx={{
          backgroundColor: (theme: Theme) =>
            locationStatus === Methods.CLAIMED ? theme.palette.customColors.primaryBackground : theme.palette.primary.main,
          color: (theme: Theme) => (locationStatus === Methods.CLAIMED ? theme.palette.primary.main : theme.palette.common.white),
        }}
      />
      <Chip
        key="chip-claim"
        label={ChipStatus.CLAIMED}
        onClick={() => handleClick(ChipStatus.CLAIMED)}
        color={locationStatus === Methods.UNCLAIMED ? "primary" : "info"}
        sx={{
          backgroundColor: (theme: Theme) =>
            locationStatus === Methods.UNCLAIMED ? theme.palette.customColors.primaryBackground : theme.palette.primary.main,
          color: (theme: Theme) => (locationStatus === Methods.UNCLAIMED ? theme.palette.primary.main : theme.palette.common.white),
        }}
      />

      {/* <Chip
        key="chip-created"
        skin={locationStatus?.includes(Methods.MANUAL) ? undefined : "light"}
        disableTheme={theme.palette?.mode === "light"}
        label={ChipStatus.CREATED}
        onClick={() => handleClick(ChipStatus.CREATED)}
        color="primary"
      /> */}
    </Stack>
  );
}
