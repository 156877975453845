import { Box, Checkbox, IconButton, Stack, Typography } from "@mui/material";
import { RoleGuard } from "guards";
import { Roles } from "models";
import { LocBulkActions } from "pages/locations/components";

import AddIcon from "@mui/icons-material/Add";
import {
  FilterChips,
  Input,
  InputApiCall,
  InputApiCallCheckboxWithOutSelectAll,
  InputApiCallInfiniteScroll,
  Select,
  TableSearch,
} from "components";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppStore } from "redux/store";
import { useLocationListContext } from "../../context";
import { useGetSearchData, useLocationSearch, useSelectLocationsCheckbox } from "../../hooks";
import { ISearchChip, optionsVisibility } from "../atoms/AppListHeader/AppListHeader";

export default function AppListHeaderMobileLoc({
  onSearch,
  redirectUrl,
  loadingExternalData,
}: {
  onSearch: Function;
  redirectUrl: string;
  loadingExternalData: boolean;
}) {
  const { locationHasFilter } = useListAndSearchContext();

  const { numSelected, onSelectAllClick, rowCount } = useSelectLocationsCheckbox();
  const { selectedClaim } = useLocationListContext();
  const { latitudeClaimMap, longitudeClaimMap, setLatitudeClaimMap, setLongitudeClaimMap } = useLocationListContext();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const nameSearchFn = useLocationSearch().locNameSearch;
  const visibilitySearch = useLocationSearch().locVisibilitySearch;
  const marketSearch = useLocationSearch().locMarketSearch;
  const purposeSearch = useLocationSearch().locPurposesSearch;
  const organizationsearch = useLocationSearch().locOrganizationSearch;

  const {
    getPurposes,
    getCountryData,
    getOrganizations,
    orgPage,
    hasNextPage,
    orgData,
    setOrgPage,
    loadingOrg,
    setOrgData,
    loadingCountry,
    loadingPurpose,
  } = useGetSearchData();

  //**Chips */
  const filterChips: ISearchChip[] = [
    {
      key: "name",
      label: "INPUT-NAME",
      data: nameSearchFn.value,
    },
    {
      key: "isVisible",
      label: "INPUT-VISIBILITY",
      data: visibilitySearch.value,
    },
    {
      key: "purposeCodes",
      label: "INPUT-PURPUSE",
      data: purposeSearch?.valueLabel,
    },
    {
      key: "organizationId",
      label: "GLOBAL-TITLE-ORGNAIZATION",
      data: organizationsearch?.valueLabel,
    },
    {
      key: "marketOrCountry",
      label: "INPUT-MARKET",
      data: marketSearch?.valueLabel,
    },
    {
      key: "coordinates",
      label: "INPUT-COORDINATES",
      data: latitudeClaimMap && longitudeClaimMap ? `${latitudeClaimMap},${longitudeClaimMap}` : "",
    },
  ];

  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);

  //Filter Chips functions
  const handleDelete = (filterName: "name" | "isVisible" | "purposeCodes" | "organizationId" | "marketOrCountry" | "coordinates") => {
    if (filterName === "name") nameSearchFn.clearByKey();
    if (filterName === "isVisible") visibilitySearch.clearByKey();
    if (filterName === "purposeCodes") purposeSearch.clearByKey();
    if (filterName === "organizationId") organizationsearch.clearByKey();
    if (filterName === "marketOrCountry") marketSearch.clearByKey();
    if (filterName === "coordinates") {
      setLatitudeClaimMap(undefined);
      setLongitudeClaimMap(undefined);
    }
    onSearch();
  };

  const handleDeleteAll = () => {
    nameSearchFn.clearSearch();
    visibilitySearch.clearSearch();
    purposeSearch.clearSearch();
    organizationsearch.clearSearch();
    marketSearch.clearSearch();
    setLatitudeClaimMap(undefined);
    setLongitudeClaimMap(undefined);
    onSearch();
  };

  return (
    <Box mx={4} mt={3}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager]}>
            <Box mt={2} ml={-4}>
              <Box display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
                <Typography variant="h5">{t("BUTTON-SELECT-ALL")}</Typography>
              </Box>
              {selectedClaim.length > 0 && (
                <Box>
                  <LocBulkActions onSearch={onSearch} hasSelectedItems={selectedClaim.length > 0} />
                </Box>
              )}
            </Box>
          </RoleGuard>
        </Box>

        <Box display="flex">
          <TableSearch
            isDashboard
            noOptionsComponent={
              <Box>
                <Stack direction="column" spacing={4} mb={4}>
                  <Input name="loc-name" {...nameSearchFn} label={`${t("INPUT-NAME")}`} fullWidth />
                  <Select
                    name="loc-visibility"
                    value={visibilitySearch.value || ""}
                    label={`${t("INPUT-VISIBILITY")}`}
                    setValue={visibilitySearch.onChange}
                    options={optionsVisibility}
                  />
                </Stack>

                <Stack direction="column" spacing={4} mb={4}>
                  <InputApiCallCheckboxWithOutSelectAll
                    setPropValue={purposeSearch.onChange as any}
                    apiCallFunction={getPurposes}
                    loading={loadingPurpose}
                    label={`${t("INPUT-PURPUSE")}`}
                    initialValue={
                      purposeSearch?.valueLabel
                        ? purposeSearch?.valueLabel
                            ?.split(",")
                            .map((item: string, index: number) => ({ label: item, id: purposeSearch.valueId?.split(",")[index] }))
                        : []
                    }
                  />

                  <InputApiCall
                    name="loc-market-search"
                    setPropValue={marketSearch.onChange}
                    apiCallFunction={getCountryData}
                    loading={loadingCountry}
                    label={`${t("INPUT-MARKET")}`}
                    externalValue={{ label: marketSearch.valueLabel || "", id: marketSearch.valueId || "" }}
                  />
                </Stack>
                {!userState?.organization?.id && (
                  <Box mb={4}>
                    <InputApiCallInfiniteScroll
                      initialValue={{ label: organizationsearch.valueLabel || "", id: organizationsearch.valueId || "" }}
                      name="loc-organization-search"
                      setPropValue={organizationsearch.onChange}
                      changeWithInputText
                      apiCallFunction={getOrganizations}
                      loading={loadingOrg}
                      label={`${t("GLOBAL-TITLE-ORGNAIZATION")}`}
                      hasNextPage={hasNextPage}
                      page={orgPage}
                      options={orgData}
                      setPage={setOrgPage}
                      setOptions={setOrgData}
                      search
                    />
                  </Box>
                )}
              </Box>
            }
            onReset={handleDeleteAll}
            onSubmit={(e: any) => {
              e.preventDefault();
              onSearch();
            }}
          />
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
            <IconButton
              onClick={() => {
                navigate(`${redirectUrl}`);
              }}
              disabled={loadingExternalData}
              color="inherit"
              sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" }, padding: "6px" }}
            >
              <AddIcon sx={{ fontSize: 25 }} />
            </IconButton>
          </RoleGuard>
        </Box>
      </Box>
      {!!locationHasFilter && (
        <Stack direction="row" spacing={4} alignItems="center" mt={2} mb={2} pb={0}>
          <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
        </Stack>
      )}
    </Box>
  );
}
