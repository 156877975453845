// ** MUI Imports
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";

// ** Icon Imports
// import Icon from 'src/@core/components/icon'
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
// ** Theme Config Import
import themeConfig from "config/themeConfig";

// ** Type Import
import { LayoutProps } from "models/layoutsTypes";

import { useGlobalContext } from "context/globalContext";
// ** Components
import AppBar from "./components/vertical/appBar";
// import Customizer from "./components/customizer";
import Navigation from "./components/vertical/navigation";
import ScrollToTop from "./components/scroll-to-top";

const VerticalLayoutWrapper = styled("div")({
  height: "100%",
  display: "flex",
});

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  flexDirection: "column",
});

const ContentWrapper = styled("main")(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  padding: theme.spacing(4),
  transition: "padding .25s ease-in-out",
  [theme.breakpoints.down("md")]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const VerticalLayout = (props: LayoutProps) => {
  // ** Props
  const { settings, children, scrollToTop, contentHeightFixed, verticalLayoutProps } = props;

  // ** Vars
  const { skin, navHidden, contentWidth } = settings;
  const { navigationSize, collapsedNavigationSize } = themeConfig;
  const navWidth = navigationSize;
  const navigationBorderWidth = skin === "bordered" ? 1 : 0;
  const collapsedNavWidth = collapsedNavigationSize;

  // ** States
  const { navVisible, setNavVisible } = useGlobalContext();

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible);

  return (
    <>
      <VerticalLayoutWrapper className="layout-wrapper">
        {/* Navigation Menu */}
        {navHidden && !(navHidden && settings.lastLayout === "horizontal") ? null : (
          <Navigation
            navWidth={navWidth}
            navVisible={navVisible}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            navMenuContent={verticalLayoutProps.navMenu.content}
            navMenuBranding={verticalLayoutProps.navMenu.branding}
            menuLockedIcon={verticalLayoutProps.navMenu.lockedIcon}
            verticalNavItems={verticalLayoutProps.navMenu.navItems}
            navMenuProps={verticalLayoutProps.navMenu.componentProps}
            menuUnlockedIcon={verticalLayoutProps.navMenu.unlockedIcon}
            afterNavMenuContent={verticalLayoutProps.navMenu.afterContent}
            beforeNavMenuContent={verticalLayoutProps.navMenu.beforeContent}
            {...props}
          />
        )}
        <MainContentWrapper className="layout-content-wrapper" sx={{ ...(contentHeightFixed && { maxHeight: "100vh" }) }}>
          {/* AppBar Component */}
          <AppBar
            toggleNavVisibility={toggleNavVisibility}
            appBarContent={verticalLayoutProps.appBar?.content}
            appBarProps={verticalLayoutProps.appBar?.componentProps}
            {...props}
          />

          {/* Content */}
          <ContentWrapper
            className="layout-page-content"
            sx={{
              mt: -4,
              ...(contentHeightFixed && {
                overflow: "hidden",
                "& > :first-of-type": { height: "100%" },
              }),
              ...(contentWidth === "boxed" && {
                mx: "auto",
                "@media (min-width:1440px)": { maxWidth: 1440 },
                "@media (min-width:1200px)": { maxWidth: "100%" },
              }),
            }}
          >
            {children}
          </ContentWrapper>

          {/* Footer Component */}
          {/* <Footer footerStyles={footerProps?.sx} footerContent={footerProps?.content} {...props} /> */}
        </MainContentWrapper>
      </VerticalLayoutWrapper>

      {/* Customizer */}
      {/* {disableCustomizer || hidden ? null : <Customizer />} */}

      {/* Scroll to top button */}
      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        //! delete the display id needed
        <ScrollToTop className="mui-fixed">
          <Fab color="secondary" size="medium" aria-label="scroll back to top" sx={{ display: "none" }}>
            <ExpandLessOutlinedIcon sx={{ fontSize: 16 }} />
          </Fab>
        </ScrollToTop>
      )}
    </>
  );
};

export default VerticalLayout;
