import { IconButton, Theme, Tooltip, useMediaQuery } from "@mui/material";
//** Assets */
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { useTranslation } from "react-i18next";

export default function WarningTooltip({ table }: { table?: boolean }) {
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Tooltip title={table ? t("WARNING-ALERT-LOCATION") : t("WARNING-ALERT")} placement="top">
      <IconButton sx={table ? { padding: 0 } : { marginRight: "12px", marginLeft: "8px", padding: 1 }} aria-label="warningIcon">
        <WarningAmberIcon color="warning" sx={{ fontSize: underMd ? 18 : 22 }} />
      </IconButton>
    </Tooltip>
  );
}
