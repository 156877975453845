import { AppBar, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useOrganizationInfoContext } from "../../context/OrganizationInfoContext";

export default function OrgTabsMenuMobile() {
  const { orgMobileTab, setOrgMobileTab } = useOrganizationInfoContext();
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setOrgMobileTab(newValue);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "background.paper", boxShadow: "none" }}>
      <Tabs
        value={orgMobileTab}
        onChange={handleChange}
        variant="fullWidth"
        sx={{ backgroundColor: "background.paper", pointerEvents: "all" }}
      >
        <Tab label={t("ORG-TAB-MOBILE-1")} />
        <Tab label={t("ORG-TAB-MOBILE-2")} />
        <Tab label={t("ORG-TAB-MOBILE-3")} />
      </Tabs>
    </AppBar>
  );
}
