import { Box, Card, LinearProgress, Typography } from "@mui/material";
import "./style.css";
import { useGlobalContext } from "context/globalContext";

export default function ActionLoader() {
  const { modalLoader } = useGlobalContext();
  return (
    <>
      {modalLoader?.open ? (
        <Card className="loader-card">
          <Typography variant="h5">{modalLoader.message}</Typography>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </Card>
      ) : null}
    </>
  );
}
