import { Box } from "@mui/material";
import { Button } from "components";
import { PrivateRoutes } from "models";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IButtonsStepperOrgForm {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  disableStep1?: boolean;
  disableStep2?: boolean;
  disableStep3?: boolean;
}
export default function ButtonsStepperOrgForm({
  activeStep,
  setActiveStep,
  disableStep1,
  disableStep2,
  disableStep3,
}: IButtonsStepperOrgForm) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box position="fixed" bottom={0} width="100%" p={8} sx={{ backgroundColor: "background.paper", zIndex: 10000 }}>
      <Box display="flex" justifyContent="space-between">
        <Button
          label={t("BUTTON-BACK")}
          name="back"
          size="large"
          variant="outlined"
          onClick={() => {
            if (activeStep === 0) {
              navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`, { replace: false });
            } else setActiveStep((prev) => prev - 1);
          }}
        />
        <Button
          label={t("BUTTON-NEXT")}
          name="next"
          size="large"
          disabled={
            (activeStep === 0 && disableStep1) ||
            (activeStep === 1 && disableStep2) ||
            (activeStep === 2 && disableStep3) ||
            (activeStep === 3 && false)
          }
          onClick={() => {
            setActiveStep((prev) => prev + 1);
          }}
        />
      </Box>
    </Box>
  );
}
