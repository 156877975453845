import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, styled, Theme, Typography, useMediaQuery } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { SyntheticEvent, useState } from "react";
import FacilityInformationInputs from "./FacilityInformationInputs";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: 8,

  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(-90deg)",
    marginLeft: theme.spacing(3),
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
    marginLeft: theme.spacing(3),
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
}));

export default function FacilityInformation() {
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  //**Context */

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (underMd) {
    return <FacilityInformationInputs />;
  }
  return (
    <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} id="locAddressInfo">
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: "24px" }} />} sx={{ px: 0 }}>
        <Typography variant="h3" sx={{ fontWeight: 600, letterSpacing: "0.18px", mt: 4, mb: 4, px: 0 }}>
          Facility Information
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FacilityInformationInputs />
      </AccordionDetails>
    </Accordion>
  );
}
