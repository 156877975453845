import { AppBar, Tab, Tabs } from "@mui/material";
import { useLocationContext } from "pages/locations/context";
import { useTranslation } from "react-i18next";

export default function LocTabsMenuMobile() {
  const { locMobileTab, setLocMobileTab } = useLocationContext();
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setLocMobileTab(newValue);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "background.paper", boxShadow: "none" }}>
      <Tabs
        value={locMobileTab}
        onChange={handleChange}
        variant="fullWidth"
        sx={{ backgroundColor: "background.paper", pointerEvents: "all" }}
      >
        <Tab label={t("ORG-TAB-MOBILE-1")} />
        <Tab label={t("ORG-TAB-MOBILE-3")} />
      </Tabs>
    </AppBar>
  );
}
