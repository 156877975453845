/* eslint-disable no-shadow */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
// ** MUI Imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// ** Icon Imports

import logo from "assets/Logo_main.png";

// ** Type Import
import { Settings } from "context/settingsContext";
import MenuIcon from "@mui/icons-material/Menu";
// ** Components
// import Autocomplete from "layouts/components/Autocomplete";
import ModeToggler from "core/layouts/components/shared-components/ModeToggler";
import UserDropdown from "core/layouts/components/shared-components/UserDropdown";
// import LanguageDropdown from "core/layouts/components/shared-components/LanguageDropdown";
import { Link, styled, Theme, Typography, TypographyProps, useMediaQuery, useTheme } from "@mui/material";

//** Redux */
import ApplicationsDropdown from "core/layouts/components/shared-components/ApplicationsDropdown";
import OrganizationsDropdown from "core/layouts/components/shared-components/OrganizationsDropdown";
import NotificationDropdown from "core/layouts/components/shared-components/NotificationDropdown";
import { useRoleGuard } from "hooks";
import { PrivateRoutes, Roles } from "models";

import themeConfig from "config/themeConfig";

interface Props {
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
}

const HeaderTitle = styled(Typography)<TypographyProps>({
  fontWeight: 700,
  lineHeight: 1.2,
  transition: "opacity .25s ease-in-out, margin .25s ease-in-out",
  marginLeft: 6,
});

const StyledLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  marginLeft: 8,
});

const AppBarContent = (props: Props) => {
  // ** Props
  const { settings, saveSettings, toggleNavVisibility } = props;

  const canSeeMenu = useRoleGuard([Roles.superAdmin, Roles.multiOrgManager, Roles.multiOrgViewer]);

  const showNavMenu = canSeeMenu;
  const theme = useTheme();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: (theme: Theme) => theme.spacing(0, 6),
        [theme.breakpoints.down("sm")]: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
      }}
    >
      <Box className="actions-left" sx={{ mr: 2, display: "flex", alignItems: "center" }}>
        {showNavMenu && !settings.navHidden ? (
          <IconButton
            color="inherit"
            sx={{ ml: -2.75, "&:focus": { outline: "0px auto -webkit-focus-ring-color" } }}
            onClick={toggleNavVisibility}
          >
            <MenuIcon sx={{ fontSize: 25 }} />
          </IconButton>
        ) : null}
        <StyledLink href={`/${PrivateRoutes.ORGANIZATIONS}`}>
          <img src={logo} alt="logo" />

          {!underMd && (
            <HeaderTitle variant="h4" width={100}>
              {themeConfig.templateName}
            </HeaderTitle>
          )}
        </StyledLink>

        {/* <Autocomplete hidden={hidden} settings={settings} /> */}
      </Box>
      <Box className="actions-right" sx={{ display: "flex", alignItems: "center" }}>
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <OrganizationsDropdown settings={settings} saveSettings={saveSettings} />
        <NotificationDropdown settings={settings} />
        <ApplicationsDropdown settings={settings} saveSettings={saveSettings} />
        {/* <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  );
};

export default AppBarContent;
