/* eslint-disable consistent-return */
/* eslint-disable curly */
/* eslint-disable nonblock-statement-body-position */
//**Components */
import { Box, Stack, Theme, useMediaQuery } from "@mui/material";
import { Button, ModalConfirm } from "components";
import config from "config/settings.json";
//**Hooks */
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
//**Context */
import { useLocationContext } from "pages/locations/context";
//**Icon */
import { days } from "constants/globalConstants";
import { useGlobalContext } from "context/globalContext";
import { UnderMdGuard } from "guards";
import { PrivateRoutes } from "models";
import { useRequiredFields } from "pages/locations/hooks";
import { useEffect, useState } from "react";
import useQuery from "utilities/getLocation";
import ButtonsStepperLocForm from "../../mobile/ButtonsStepperLocForm";

export default function ButtonStepsCreate({
  disabled,
  onClickLastStep,
  urlToGoBack,
  setActiveStep,
  activeStep,
}: {
  disabled?: boolean;
  urlToGoBack?: string;
  onClickLastStep: () => void;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
  activeStep?: number;
}) {
  //** Stata */
  const [showModal, setShowModal] = useState(false);
  //**Context */
  const { setAlertRequiredFields, setOrganization, locHasChanges, setGoBackModalLoc } = useLocationContext();
  const { urlHistory } = useGlobalContext();
  //**Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { requiredInputStatus, requiredInputStatus2, requiredInputStatus3, requiredInputStatus4 } = useRequiredFields();
  const requiredInputsEmpty = [requiredInputStatus, requiredInputStatus2, requiredInputStatus3, requiredInputStatus4];

  const queryUrl = useQuery();

  const mobileStyles = {
    position: "fixed",
    bottom: 0,
    width: "100%",
    px: 8,
    pb: 8,
    backgroundColor: "background.paper",
    zIndex: 10000,
  };

  //** Context */
  const { name, city, postalCode, latitude, longitude, state, googleMapsInputs, region, marketCountry, phone, openingHours, view } =
    useLocationContext();

  //** Google inconsistences */
  const marketGoogleCompare = !!marketCountry?.id && !!googleMapsInputs?.market && googleMapsInputs?.market !== marketCountry.label;
  const regionGoogleCompare = !!region?.id && !!googleMapsInputs?.region && googleMapsInputs?.region !== region.label;
  const stateGoogleCompare = !!state && !!googleMapsInputs?.state && googleMapsInputs?.state !== state;
  const cityGoogleCompare = !!city && !!googleMapsInputs?.city && googleMapsInputs?.city !== city;
  const postalCodeGoogleCompare = !!postalCode && !!googleMapsInputs?.postalCode && googleMapsInputs?.postalCode !== postalCode;
  const longGoogleCompare =
    !!longitude && !!googleMapsInputs?.coordinates_longitude && `${googleMapsInputs?.coordinates_longitude}` !== `${longitude}`;
  const latGoogleCompare =
    !!latitude && !!googleMapsInputs?.coordinates_latitude && `${googleMapsInputs?.coordinates_latitude}` !== `${latitude}`;

  const phoneGoogleCompare = !!phone && !!googleMapsInputs?.phone && googleMapsInputs?.phone !== phone && view === "create";
  const openHoursGoogleCompare = openingHours.map((hourData, index): boolean => {
    //** Compare if the address have open hours */
    if (
      googleMapsInputs?.openingHours[days[index]]?.opening.length === 0 ||
      googleMapsInputs?.openingHours[days[index]]?.closing.length === 0
    ) {
      return false;
    }
    //** If the address have open hours compare if the user change this hour */
    if (
      !googleMapsInputs?.openingHours[days[index]].opening.includes(hourData.opening) ||
      !googleMapsInputs?.openingHours[days[index]]?.closing.includes(hourData.closing) ||
      !googleMapsInputs?.openingHours[days[index]]?.is_enabled === hourData.is_enabled
    ) {
      return true;
    }
    return false;
  });
  const openHoursGoogleCompareBoolean = openHoursGoogleCompare.some((value) => value === true);

  const googleInconsistent =
    marketGoogleCompare ||
    stateGoogleCompare ||
    cityGoogleCompare ||
    postalCodeGoogleCompare ||
    longGoogleCompare ||
    latGoogleCompare ||
    regionGoogleCompare ||
    phoneGoogleCompare ||
    openHoursGoogleCompareBoolean;

  // eslint-disable-next-line consistent-return

  useEffect(() => {
    if (queryUrl.get("orgId")) {
      setOrganization({ id: queryUrl.get("orgId"), label: queryUrl.get("orgName") });
    }
  }, [name]);

  const handleLastStep = () => {
    if (requiredInputsEmpty?.some((value) => value === true)) return setAlertRequiredFields([...requiredInputsEmpty]);
    if (googleInconsistent) return handleOpenModal();
    if (onClickLastStep) onClickLastStep();
  };

  if (underMd && activeStep !== 3) {
    return (
      <ButtonsStepperLocForm
        activeStep={activeStep || 0}
        setActiveStep={setActiveStep || (() => {})}
        disableStep1={requiredInputStatus}
        disableStep2={false}
        disableStep3={requiredInputStatus2 || requiredInputStatus3}
        urlToGo={urlToGoBack || ""}
      />
    );
  }

  return (
    <Box sx={underMd ? { ...mobileStyles } : {}}>
      <Box display="flex" mt={8}>
        <Stack spacing={2} direction="row" justifyContent="space-between" width="100%">
          <UnderMdGuard>
            <Button
              name="loc-cancel"
              label={`${t("BUTTON-CANCEL")}`}
              variant="outlined"
              onClick={() => {
                const hasLastPage = document.referrer.indexOf(`${config.HOST_URL}`) !== -1 || urlHistory.length > 0;
                const urlToGo = hasLastPage ? -1 : `/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.LIST}`;
                if (locHasChanges) return setGoBackModalLoc({ open: true, urlToGo: `${urlToGo}` });
                return navigate(`${urlToGo}`);
              }}
            />
          </UnderMdGuard>
          <UnderMdGuard showOnMobile>
            <Button
              name="org-cancel-create"
              onClick={() => {
                setActiveStep && setActiveStep((prev) => prev - 1);
              }}
              label={t("BUTTON-BACK")}
              size="large"
              variant="outlined"
            />
          </UnderMdGuard>
          <Button
            name="loc-create"
            label={`${t("BUTTON-CREATE")}`}
            onClick={handleLastStep}
            disabled={underMd ? requiredInputStatus4 : disabled}
          />
        </Stack>
        <ModalConfirm
          open={showModal}
          handleClose={handleCloseModal}
          handleConfirm={() => {
            onClickLastStep();
            handleCloseModal();
          }}
          title="Next step?"
          description="You have inconsistent information with google maps. Are you sure you want to continue?"
          sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
          sxTitle={{ fontSize: "2rem !important" }}
        />
      </Box>
    </Box>
  );
}
