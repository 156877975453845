//** Components imports */
import UserLayout from "layouts/UserLayout";
import { PrivateRoutes, PublicRoutes } from "models/routes";
//** External imports */
import { Navigate, Outlet, useLocation } from "react-router-dom";
//** Redux */
import { useGlobalContext } from "context/globalContext";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

export const AuthGuard = () => {
  const userState = useSelector((store: AppStore) => store.user);
  const location = useLocation();

  const { setUrlHistory } = useGlobalContext();
  useEffect(() => {
    setUrlHistory((prev) => [...prev, location.pathname]);
  }, []);

  return userState?.token ? (
    <>
      {location.pathname === `/${PrivateRoutes.SELECT_ORGANIZARION}` ? (
        <Outlet />
      ) : (
        <UserLayout>
          <Outlet />
        </UserLayout>
      )}
    </>
  ) : (
    <Navigate to={PublicRoutes.LOGIN} replace={false} state={{ location }} />
  );
};

export default AuthGuard;
