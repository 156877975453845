/* eslint-disable import/no-cycle */
import { FormControl, FormControlLabel, RadioGroup, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import ExportModal from "components/molecules/ExportModal/ExportModal";
import { exportModalActions } from "constants/globalConstants";
import { useGlobalContext } from "context/globalContext";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useLocationListContext } from "pages/locations/pages";

import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import SuccessModalLocation from "../../organism/ImportModalLocations/SuccessModalLocation";

export default function ModalLocationExports({
  exportLocations,
  open,
  handleClose,
  openSuccessModal,
  setOpenSuccessModal,
}: {
  exportLocations: () => Promise<void>;
  open: boolean;
  handleClose: () => void;
  openSuccessModal: boolean;
  setOpenSuccessModal: Dispatch<SetStateAction<boolean>>;
}) {
  //** States */
  const { selectedClaim } = useLocationListContext();
  const { locationHasFilter } = useListAndSearchContext();
  const { exportLocationFilter, setExportLocationFilter } = useGlobalContext();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExportLocationFilter((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <ExportModal open={open} handleClose={handleClose} handleConfirm={exportLocations}>
        <Typography variant="h5">{t("LOC-EXPORT-TITLE")} </Typography>
        <FormControl sx={{ ml: 4, mt: 4 }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={exportModalActions.EXPORT_ALL}
            onChange={handleChange}
            value={exportLocationFilter}
          >
            <FormControlLabel value={exportModalActions.EXPORT_ALL} control={<Radio />} label={t("LOC-EXPORT-ITEM-2")} />
            <FormControlLabel
              value={exportModalActions.EXPORT_SELECTED_ITEMS}
              control={<Radio />}
              label={t("LOC-EXPORT-ITEM-3")}
              disabled={selectedClaim.length === 0}
            />
            <FormControlLabel
              value={exportModalActions.EXPORT_CURRENT_SEARCH}
              control={<Radio />}
              label={t("LOC-EXPORT-ITEM-4")}
              disabled={!locationHasFilter}
            />
          </RadioGroup>
        </FormControl>
      </ExportModal>

      <SuccessModalLocation
        open={openSuccessModal}
        title={t("EXPORT-SUCCESS-TITLE")}
        description={
          <Typography textAlign="center" variant="h5" mb={4}>
            {t("EXPORT-SUCCESS-DESC-1")}
            <Typography variant="h5" component="span" fontWeight="bold">
              {t("EXPORT-SUCCESS-DESC-2")}
            </Typography>
            {t("EXPORT-SUCCESS-DESC-3")}
          </Typography>
        }
        handleClose={() => {
          setOpenSuccessModal(false);
        }}
      />
    </>
  );
}
