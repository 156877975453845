/* eslint-disable consistent-return */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import isUrl from "is-url";
import { useLocationContext } from "../context";
import { purposeCodeforAplicableBrand } from "../constants/purposesToConditions";

export default function useRequiredFields() {
  //**Context */
  const { name, organization, applicableBrand, view, setAlertRequiredFields } = useLocationContext();
  const { address1, marketCountry, city, postalCode, latitude, longitude, state, region } = useLocationContext();
  const { email, phone, website, isValidEmail, initalUrl, isValidPhone } = useLocationContext();
  //** Step4 */
  const { purpose, isValidSBDUSEmails, keyContacts } = useLocationContext();
  //* Aditional*/
  const { displayOrder } = useLocationContext();
  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);

  //**Basic step */
  const requiredInputStatus = !name || (userState?.organization ? !userState.organization : !organization?.label);
  //**Address step */
  const requiredInputStatus2 = !address1 || !city || !postalCode || !latitude || !longitude || !state || !region?.id || !marketCountry?.id;
  //**Contact step */

  const requiredInputStatus3 =
    (email?.length > 0 && !isValidEmail) ||
    (phone?.length > 0 && !isValidPhone) ||
    (!isUrl(`${initalUrl}${website}`) && website.length > 0) ||
    !isValidSBDUSEmails.mngDirectorEmail ||
    keyContacts?.some((item) => !item.isValidEmail);

  //**Aditional step */
  const requiredInputStatus4 =
    (purpose.findIndex((item) => item.code === "OR") !== -1 ? !displayOrder : false) ||
    purpose.length === 0 ||
    (purpose.some((item) => purposeCodeforAplicableBrand.includes(`${item.code}`)) && applicableBrand.length === 0);

  //** Validate required fields */

  useEffect(() => {
    //** On create view the fields are validating when clicks "next" or "create button" */
    //** in the other views (update) always will be validating the fileds */
    if (view === "create") return;
    if (requiredInputStatus) {
      setAlertRequiredFields((prev) => {
        prev.splice(0, 1, true);
        return prev;
      });
    } else {
      setAlertRequiredFields((prev) => {
        prev.splice(0, 1, false);
        return prev;
      });
    }
    if (requiredInputStatus2) {
      setAlertRequiredFields((prev) => {
        prev.splice(1, 1, true);
        return prev;
      });
    } else {
      setAlertRequiredFields((prev) => {
        prev.splice(1, 1, false);
        return prev;
      });
    }

    if (requiredInputStatus3) {
      setAlertRequiredFields((prev) => {
        prev.splice(2, 1, true);
        return prev;
      });
    } else {
      setAlertRequiredFields((prev) => {
        prev.splice(2, 1, false);
        return prev;
      });
    }

    if (requiredInputStatus4) {
      setAlertRequiredFields((prev) => {
        prev.splice(3, 1, true);
        return prev;
      });
    } else {
      setAlertRequiredFields((prev) => {
        prev.splice(3, 1, false);
        return prev;
      });
    }
  }, [requiredInputStatus, requiredInputStatus2, requiredInputStatus3, requiredInputStatus4]);

  return { requiredInputStatus, requiredInputStatus2, requiredInputStatus3, requiredInputStatus4 };
}
