/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { ModalConfirm } from "components";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { RoleGuard } from "guards";
import { useFetchAndLoad, useToastMui } from "hooks";
import { PrivateRoutes, Roles } from "models";
import useDeleteUsers from "pages/users/hooks/useDeleteUsers";
import { IBulkUser, IUsersListDOM } from "pages/users/models";
import { useUsersListContext } from "pages/users/pages/usersList/context";
import { bulkUserAPI } from "pages/users/services";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

enum BulkActions {
  ACTIVE = "ACTIVE",
  SUSPEND = "SUSPEND",
  DELETE = "DELETE",
}

interface IBulk {
  is_suspended?: boolean;
  action?: BulkActions;
}

export default function UserFastActions({
  onSearch,
  id,
  isActive,
  handleMouseLeave,
}: {
  onSearch: Function;
  id: string;
  isActive: boolean;
  handleMouseLeave?: (id: string) => void;
}) {
  //** Context */
  const { usersArray, setUsersArray } = useUsersListContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [bulkObject, setBulkObject] = useState<IBulk>({});
  const [tempState, setTempState] = useState<IUsersListDOM[]>([]);

  //** Hooks */
  const { callEndpoint } = useFetchAndLoad();
  const navigate = useNavigate();
  const { handleCreateToast } = useToastMui();
  const { setUserGetToggle } = useListAndSearchContext();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (handleMouseLeave) handleMouseLeave(id);
  };

  //** Function */
  const handleBulk = async () => {
    try {
      //** Save old state with optimistic update*/
      setTempState(usersArray);

      //** update loaded elements */
      const orgIndex = usersArray.findIndex((item) => item.id === id);
      setUsersArray((prev) => {
        const orgCopy = [...prev];
        orgCopy[orgIndex] = { ...prev[orgIndex], isSuspended: bulkObject.is_suspended ?? prev[orgIndex].isSuspended };
        return [...orgCopy];
      });

      const body: IBulkUser = { ...bulkObject, item_id: [id] };
      setOpenModal(false);
      await callEndpoint(bulkUserAPI({ body }), (err: any) => handleCreateToast(err.response.data.message, "error"));
      setUserGetToggle((prev: boolean) => !prev);
      handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
    } catch (error) {
      console.log(error);
      setUsersArray(tempState);
    }
  };
  const { deleteUsers } = useDeleteUsers();
  // Handle  dialog
  const handleClickOpen = (bulkQuery: IBulk) => {
    setOpenModal(true);
    setBulkObject(bulkQuery);
  };
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseDelete = () => setOpenModalDelete(false);

  const modalTitle = bulkObject.action === BulkActions.ACTIVE ? "Activate 1 users?" : "Suspend 1 users?";

  const modalContent = bulkObject.action === BulkActions.ACTIVE ? t("BULK-USER-ACTIVATE") : `${t("BULK-USER-SUSPEND")}`;

  const handleChangeActive = (isActive: boolean) => {
    const action = isActive ? BulkActions.ACTIVE : BulkActions.SUSPEND;
    handleClickOpen({ is_suspended: !isActive, action });
  };

  return (
    <Box onClick={(e) => e.stopPropagation()} display="inline-block" onTouchStart={(e) => e.stopPropagation()}>
      <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
        <IconButton
          color="primary"
          aria-label="edit-organization"
          sx={{
            "&:focus": {
              outline: "0px auto -webkit-focus-ring-color",
            },
            p: 0,
            m: 0,
          }}
          onClick={handleClick}
        >
          <MoreVertIcon sx={{ fontSize: "2.5rem" }} color="secondary" />
        </IconButton>
      </RoleGuard>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.INFORMATION_NAV}/${id}`);
            handleClose();
          }}
        >
          <Typography variant="h5">{t("GLOBAL-BULK-ACTIONS-EDIT")}</Typography>
        </MenuItem>
        {isActive ? (
          <MenuItem
            onClick={() => {
              handleChangeActive(false);
              handleClose();
            }}
          >
            <Typography variant="h5">{t("OPTION-SELECT-1")}</Typography>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleChangeActive(true);
              handleClose();
            }}
          >
            <Typography variant="h5">{t("OPTION-SELECT-2")}</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setOpenModalDelete(true);
            handleClose();
          }}
        >
          <Typography variant="h5">{t("ICON-DELETE")}</Typography>
        </MenuItem>
      </Menu>
      <ModalConfirm
        open={openModal}
        title={modalTitle}
        description={modalContent}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleCloseModal}
        handleConfirm={handleBulk}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
      <ModalConfirm
        open={openModalDelete}
        title="Delete 1 users?"
        description={t("USERS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleCloseDelete}
        handleConfirm={() => {
          deleteUsers([id]);
          setOpenModalDelete(false);
          onSearch();
        }}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </Box>
  );
}
