import { useState } from "react";
// import AddIcon from "@mui/icons-material/Add";
import AddIcon from "@mui/icons-material/Add";
import { Box, styled, Typography } from "@mui/material";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DrawerActionMobile from "components/molecules/DrawerMobile/DrawerActionMobile";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";
import { ModalLocationExports } from "pages";
import { useExportLocation } from "pages/locations/hooks";
import LocationImportOnHeader from "pages/locations/pages/locationsList/components/atoms/AppListHeader/LocationImportOnHeader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type BaseProps = {
  absolute?: boolean;
};

export default function LocSpeedDial({ absolute }: BaseProps) {
  const { setOpenImportModal } = useListAndSearchContext();
  const { openExport, setOpenexport } = useListAndSearchContext();
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [callStatus, setCallStatus] = useState<"success" | "error" | null>(null);

  //** States Export */
  //** Var */
  const { exportLocations, setOpenSuccessModal, openSuccessModal } = useExportLocation();

  const handleCloseExport = () => setOpenexport(false);

  //**Redux */

  const authorizedRoles = [Roles.superAdmin, Roles.organizationManager, Roles.multiOrgManager, Roles.locationManager];

  const LocSpeedDialStyled = styled("div")(({ theme }) => ({
    zIndex: 11,
    position: absolute ? "absolute" : "fixed",
    right: theme.spacing(6), //6
    bottom: theme.spacing(10), //10
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  }));

  return (
    <Box>
      <RoleGuard authorizedRoles={authorizedRoles}>
        <LocSpeedDialStyled
          onClick={() => {
            setOpenDrawer(true);
          }}
          sx={{
            backgroundColor: "primary.main",
            borderRadius: "8px",
            width: 60,
            height: 56,
          }}
        >
          <AddIcon sx={{ fontSize: 28, fontWeight: 700, color: "#FFF" }} />
        </LocSpeedDialStyled>
        <LocationImportOnHeader callStatus={callStatus} setCallStatus={setCallStatus} />
        <ModalLocationExports
          open={openExport}
          exportLocations={exportLocations}
          handleClose={handleCloseExport}
          setOpenSuccessModal={setOpenSuccessModal}
          openSuccessModal={openSuccessModal}
        />
      </RoleGuard>

      {openDrawer && (
        <DrawerActionMobile
          drawerBleeding={110}
          closeDrawer={() => {
            setOpenDrawer(false);
          }}
        >
          <Box sx={{ pointerEvents: "all" }} px={16}>
            <Box
              display="flex"
              alignContent="center"
              gap={4}
              my={6}
              onClick={() => {
                setOpenDrawer(false);
                navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.CREATE}`, { replace: false });
              }}
            >
              <ModeEditOutlineOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography variant="h5">{t("BUTTON-CREATE")}</Typography>
            </Box>
            <Box
              display="flex"
              alignContent="center"
              gap={4}
              onClick={() => {
                setOpenDrawer(false);

                setOpenImportModal(true);
                setCallStatus(null);
              }}
            >
              <FileUploadOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography variant="h5">{t("BUTTON-IMPORT")}</Typography>
            </Box>
          </Box>
        </DrawerActionMobile>
      )}
    </Box>
  );
}
