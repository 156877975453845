import { Dispatch, SetStateAction } from "react";

export default function useLocTables(
  setSelected: Dispatch<SetStateAction<{ id: string; isHQ: boolean; status: boolean }[]>>,
  selected: { id: string; isHQ: boolean; status: boolean }[],
  listArray: any[],
) {
  const handleClickTableCheckbox = (id: string, isHq: boolean, status: boolean) => {
    const selectedIndex = selected.findIndex((item) => item.id === `${id}`);
    let newSelected: { id: string; isHQ: boolean; status: boolean }[] = [...selected];

    if (selectedIndex === -1) newSelected = [...selected, { id, isHQ: isHq, status }];
    else newSelected.splice(selectedIndex, 1);

    setSelected([...newSelected]);
  };

  const handleClickTableCheckboxMobile = (id: string, isHq: boolean, status: boolean) => {
    const selectedIndex = selected.findIndex((item) => item.id === `${id}`);
    let newSelected: { id: string; isHQ: boolean; status: boolean }[] = [...selected];

    if (selectedIndex === -1) {
      newSelected = [...selected, { id, isHQ: isHq, status }];
    } else newSelected.splice(selectedIndex, 1);

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = listArray.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string) => selected.findIndex((item) => item.id === `${id}`) !== -1;

  return { handleClickTableCheckbox, isSelected, handleSelectAllClick, handleClickTableCheckboxMobile };
}
