import { Box, Skeleton } from "@mui/material";
import { DrawerMobile, Maps } from "components";
import { LocationsList, useLocationListContext } from "pages/locations";
import useGetLocationsDashboard from "pages/locations/pages/locationsList/hooks/useLocationsDashboard";
import { UsersList } from "pages/users";
import { useParams } from "react-router-dom";
import { useOrganizationInfoContext } from "../../context/OrganizationInfoContext";
import OrgDrawerHeader from "./OrgDrawerHeader";
import OrgAboutMobile from "./OrgMobileTabs/OrgAboutMobile";

interface IOrgDashboardMobile {
  logo: string;
  name: string;
  url: string;
  isActive: boolean;
  address: string;
  email: string;
  phone: string;
  region: string;
  loading?: boolean;
  handleUpdateStatus?: () => void;
  redirectUrlUser?: string;
}
export default function OrgDashboardMobile(props: IOrgDashboardMobile) {
  const { logo, name, url, isActive, loading, handleUpdateStatus, address, email, phone, region, redirectUrlUser } = props;
  const { id } = useParams();

  useGetLocationsDashboard(`${id || ""}`);
  const { locationsMapMarksArray } = useLocationListContext();
  const { orgMobileTab } = useOrganizationInfoContext();

  return (
    <div>
      <Maps
        zoom={12}
        positions={locationsMapMarksArray}
        mapHeight="calc(100dvh - 180px)"
        options={{ mapTypeControlOptions: { position: 3 } }}
      />
      <DrawerMobile
        drawerBleeding={170}
        header={
          <>
            {loading || !name ? (
              <Skeleton variant="rounded" height={130} sx={{ mx: 4 }} />
            ) : (
              <OrgDrawerHeader logo={logo} name={name} url={url} isActive={isActive} handleUpdateStatus={handleUpdateStatus} />
            )}
          </>
        }
      >
        <Box>
          {orgMobileTab === 0 && <OrgAboutMobile address={address} email={email} phone={phone} region={region} />}
          {orgMobileTab === 1 && <LocationsList organizationId={id} orgName={name} fromDashboard />}
          {orgMobileTab === 2 && <UsersList organizationId={id} fromDashboard redirectUrl={redirectUrlUser} />}
        </Box>
      </DrawerMobile>
    </div>
  );
}
