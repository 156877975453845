// ** MUI Imports
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

//**Hooks */
import { useTranslation } from "react-i18next";
//** Redux */
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactNode } from "react";

interface IModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  description: ReactNode;
}
export default function SuccessModalLocation({ open, handleClose, title, description }: IModalProps) {
  //**Hooks */
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="user-view-edit"
      sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: 800 } }}
      aria-describedby="user-view-edit-description"
    >
      <DialogContent sx={{ px: 16 }}>
        <Box display="flex" justifyContent="center">
          <InfoOutlinedIcon sx={{ fontSize: 36, my: 4 }} color="primary" />
        </Box>
        <Typography textAlign="center" variant="h3" mb={4}>
          {title}
        </Typography>

        {description}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", mb: 4, px: 8 }}>
        <Button
          size="large"
          variant="text"
          sx={{ mr: 1 }}
          onClick={() => {
            handleClose();
          }}
        >
          {t("BUTTON-OKAY")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
