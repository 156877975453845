/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Avatar, Box, ListItem, ListItemAvatar, ListItemButton, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import Color from "color-thief-react";
import { Chip } from "components";
import { UnderMdGuard } from "guards";
import { PrivateRoutes } from "models";
import OrgBulkActions from "pages/organizations/components/organism/OrgBulkActions/OrgBulkActions";
import { IOrganizationListDOM } from "pages/organizations/models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOrganizationListContext } from "../../../context";

export default function TableResponsiveRow({
  data,
  index,
  onClick,
  onSearch,
}: {
  data: IOrganizationListDOM;
  index: number;
  onClick?: () => void;
  onSearch: Function;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setLoad, load } = useOrganizationListContext();
  const [dobleClickId, setDobleClickId] = useState<string>("");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const under1200px = useMediaQuery("(max-width:1200px)");
  //Table logic to navigate
  const handleNavigate = (id: string) => {
    navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: false });
  };

  const imgSize = underMd ? 40 : 60;

  return (
    <ListItem sx={{ px: underMd ? 0 : 4, py: 0 }}>
      <ListItemButton
        role={undefined}
        onClick={() => {
          if (onClick && dobleClickId !== data.id) {
            onClick();
            //** Activates redirect when doble click the row */
            setDobleClickId(data.id);
          } else handleNavigate(data.id);
        }}
        sx={{
          pl: 6,
          pr: 4,
          backgroundColor: "background.paper",
          borderBottom: (theme: Theme) => `${theme.palette.divider} 1px solid`,

          display: "flex",
          alignItems: "center",
          height: !underMd ? "100px" : "auto",
          py: underMd ? 4 : 0,
        }}
      >
        <ListItemAvatar sx={{ mr: 8 }}>
          <Box display="flex">
            <Box mt={0.4}>
              <Color src={data.logo as string} crossOrigin="anonymous" format="hex">
                {({ data: dataImg, loading }) => {
                  return (
                    <>
                      <Skeleton
                        sx={{ display: load[index] || loading ? "block" : "none", ml: 4 }}
                        animation="wave"
                        variant="circular"
                        width={imgSize}
                        height={imgSize}
                      />

                      <Avatar
                        sx={{
                          display: load[index] ? "none" : "block",
                          ml: 4,
                          width: imgSize,
                          height: imgSize,
                          backgroundColor: dataImg || "transparent",
                          "& .MuiAvatar-img": { objectFit: "contain !important" },
                        }}
                        src={typeof data.logo === "string" ? data.logo : ""}
                        alt={data.name}
                        onLoad={(e: any) => {
                          if (e.type === "load") {
                            setLoad((prev: any) => {
                              const copy = prev;
                              copy[index] = false;
                              return [...copy];
                            });
                          }
                        }}
                      />
                    </>
                  );
                }}
              </Color>
            </Box>
          </Box>
        </ListItemAvatar>
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="flex-start">
            <Box width="40vw">
              <Box display="flex" alignItems="center">
                <UnderMdGuard showOnMobile>
                  {data.isSuspended ? (
                    <Box
                      mr={2}
                      bgcolor={(theme: Theme) => {
                        return theme.palette.mode === "dark" ? theme.palette.secondary.dark : theme.palette.secondary.light;
                      }}
                      borderRadius="50%"
                      width="12px"
                      height="12px"
                    />
                  ) : (
                    <Box
                      mr={2}
                      bgcolor={(theme: Theme) => (theme.palette.mode === "dark" ? theme.palette.success.dark : theme.palette.success.light)}
                      borderRadius="50%"
                      width="12px"
                      height="12px"
                    />
                  )}
                </UnderMdGuard>

                <Typography
                  variant={underMd ? "h5" : "h3"}
                  fontWeight={underMd ? "700" : "500"}
                  mb={underMd ? 0 : 2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "95%",
                  }}
                >
                  {data.name}
                </Typography>
              </Box>
              <UnderMdGuard>
                <Typography
                  variant="h5"
                  color="text.secondary"
                  sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "80%" }}
                >
                  {data.hqInformation.website || "------"}
                </Typography>
              </UnderMdGuard>
              {!underMd ? (
                <Box display="flex" mt={2} alignItems="center">
                  <LocationOnOutlinedIcon sx={{ fontSize: "2rem", mr: 2 }} color="secondary" />
                  <Typography variant="h5">{data.hqInformation.address || "------"}</Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" mt={2}>
                  <Box display="flex" alignItems="center" gap={2} width="100%">
                    <GroupOutlinedIcon sx={{ fontSize: 18 }} />
                    <Typography variant="h5" color="textSecondary">
                      {data.userCounter}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={2} width="100%">
                    <FmdGoodOutlinedIcon sx={{ fontSize: 18 }} />
                    <Typography variant="h5" color="textSecondary">
                      {data.locationCounter}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <UnderMdGuard>
              <Box width={under1200px ? "20vw" : "30vw"} display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap={4} width="100%">
                  <GroupOutlinedIcon sx={{ fontSize: 24 }} />
                  <Typography variant="h5" color="textSecondary">
                    {data.userCounter}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" gap={4} width="100%">
                  <FmdGoodOutlinedIcon sx={{ fontSize: 24 }} />
                  <Typography variant="h5" color="textSecondary">
                    {data.locationCounter}
                  </Typography>
                </Box>
                {!under1200px && (
                  <Box width="100%" display="flex" justifyContent="flex-end">
                    <Chip size="small" label={data.hqInformation.region} color="primary" sx={{ fontSize: "1.4rem" }} />
                  </Box>
                )}
              </Box>
            </UnderMdGuard>
          </Box>
          <Box alignContent="flex-end" alignItems="center" display="flex">
            <UnderMdGuard>
              {data.isSuspended ? (
                <Chip size="small" label={t("OPTION-SELECT-1")} color="secondary" sx={{ fontSize: "1.4rem" }} />
              ) : (
                <Chip size="small" label={t("OPTION-SELECT-2")} color="success" sx={{ fontSize: "1.4rem" }} />
              )}
            </UnderMdGuard>
            <UnderMdGuard showOnMobile>
              {/* this is the same chip of region on desktop but i need to change it to show on mobile and dont broke desktop structure */}
              <Box display="flex" justifyContent="flex-end">
                <Chip
                  size="small"
                  label={data.hqInformation.region || "N/A"}
                  color="primary"
                  sx={{ fontSize: "1.4rem", maxWidth: "50px" }}
                />
              </Box>
            </UnderMdGuard>
            <OrgBulkActions onSearch={onSearch} id={data.id} isActive={data.isSuspended} />
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
