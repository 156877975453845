/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
import PlaceIcon from "@mui/icons-material/Place";
import { Box, Checkbox, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import { RoleGuard } from "guards";
import useCounters from "hooks/useCounters";
import Translations from "layouts/components/Translations";
import { Roles } from "models";
import { LocBulkActions } from "pages/locations/components";
import { intToShortNotation } from "utilities/HelpService";
import { useLocationListContext } from "../../../context";
import { useSelectLocationsCheckbox } from "../../../hooks";

export default function TextHeaderLoc({
  onSearch,
  isDashboard,
  locationOrgCounter,
}: {
  onSearch: Function;
  isDashboard?: boolean;
  locationOrgCounter?: number;
}) {
  //** On responsive view this header appear on org details */
  const { selectedClaim } = useLocationListContext();
  const { counter, loading } = useCounters("locations");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { numSelected, onSelectAllClick, rowCount } = useSelectLocationsCheckbox();

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={4} alignItems="center">
      <Box>
        {isDashboard ? (
          <Box display="flex" alignItems="flex-start" ml={-3}>
            <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
              />
            </RoleGuard>
            <Box display="flex" alignItems="center">
              <Typography variant="h2" fontWeight={underMd ? "700" : "500"} mt={selectedClaim.length > 0 ? 1 : 3}>
                <Translations text="GLOBAL-TITLE-LOCATIONS" />
              </Typography>
              {loading || (isDashboard && locationOrgCounter === 0) ? (
                <Skeleton variant="text" width={30} height={30} sx={{ ml: 2, mt: 3 }} />
              ) : (
                <Typography variant="h5" ml={2} mt={5}>{` (${intToShortNotation(locationOrgCounter || counter)})`}</Typography>
              )}
              {selectedClaim.length > 0 && (
                <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager]}>
                  <Box mt={2} ml={4}>
                    <LocBulkActions onSearch={onSearch} hasSelectedItems={selectedClaim.length > 0} />
                  </Box>
                </RoleGuard>
              )}
            </Box>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <PlaceIcon sx={{ fontSize: 24, mr: 4 }} />
            <Typography variant="h2" fontWeight={underMd ? "700" : "500"}>
              <Translations text={isDashboard ? "GLOBAL-TITLE-LOCATIONS" : "GLOBAL-TITLE-ALL-LOCATIONS"} />
            </Typography>
            {loading ? (
              <Skeleton variant="text" width={30} height={30} sx={{ ml: 2 }} />
            ) : (
              <Typography variant="h5" ml={2} mt={2}>{` (${intToShortNotation(counter)})`}</Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
