//** MUI IMPORTS*/
import { Box, Theme, TypographyVariant, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
//** Images*/
import logo from "assets/Logo_login.png";

export default function LoginHeader({
  title,
  subTitle,
  subTitle2,
  paddingSecondTitle,
  variant,
  subTitle2Bold,
}: {
  title: string;
  subTitle: string;
  subTitle2?: string;
  paddingSecondTitle?: number;
  variant?: TypographyVariant | "inherit";
  subTitle2Bold?: boolean;
}) {
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <>
      <Box sx={{ mb: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img src={logo} alt="logo" />
      </Box>
      <Box sx={{ mb: 12 }}>
        <Typography variant={underMd ? "h3" : "h2"} sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
          {title}
        </Typography>
        <Typography variant={variant || "h6"}>{subTitle}</Typography>
        {subTitle2 && (
          <Typography variant={variant || "h6"} fontWeight={subTitle2Bold ? 700 : 500} mt={paddingSecondTitle || 8}>
            {subTitle2}
          </Typography>
        )}
      </Box>
    </>
  );
}
