/* eslint-disable consistent-return */
//**Hooks */
import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import useCreateLocation from "pages/locations/hooks/useCreateLocation";
import { useTranslation } from "react-i18next";
//**Components imports */
import { ButtonStepsCreate } from "../../molecules";

import { BusinessStep } from "../BusinessStep";

export default function AditionalStep({ hiddenButtons }: { hiddenButtons?: boolean }) {
  //**Hooks */
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const { handleCreateLocation, loading } = useCreateLocation();

  //** Validate required fields */

  return (
    <Box>
      <Typography variant={underMd ? "h4" : "h3"} sx={{ mb: 8.5, fontWeight: 600, letterSpacing: "0.18px" }}>
        {t("TITLE-ADDITIONAL-FIELDS")}
      </Typography>
      <BusinessStep />

      <Divider />

      {!hiddenButtons && <ButtonStepsCreate disabled={loading} onClickLastStep={handleCreateLocation} />}
    </Box>
  );
}
