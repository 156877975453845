/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
// ** MUI Imports
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Alert, Box, CircularProgress, Divider, IconButton, Typography } from "@mui/material";
//** Components imports */
import { CSVUploader } from "components";
import { getLocationImportTemplateAPI } from "pages/locations/services";
//**Hooks */
import { useTranslation } from "react-i18next";
import { useFetchAndLoad, useToastMui } from "hooks";
import { downloadFileFromResponse } from "utilities/HelpService";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { Roles } from "models";
import CloseIcon from "@mui/icons-material/Close";
import Translations from "layouts/components/Translations";

interface IModalProps {
  open: boolean;
  handleConfirm: (file: File) => void;
  handleClose: () => void;
  loading: boolean;
  callStatus: "success" | "error" | null;
}
export default function ImportModalLocations({ open, handleConfirm, handleClose, loading, callStatus }: IModalProps) {
  //**States */
  const [file, setFile] = useState<File[]>([]);
  //**Hooks */
  const { t } = useTranslation();
  const { handleCreateToast } = useToastMui();
  const { callEndpoint } = useFetchAndLoad();

  //** Redux */
  const userData = useSelector((store: AppStore) => store.user);
  const { roles } = userData;
  const isAdmin = roles === Roles.superAdmin;

  const exportTemplate = async () => {
    try {
      const query = { org_users: !isAdmin };
      const response = await callEndpoint(getLocationImportTemplateAPI({ query }));
      downloadFileFromResponse(response, ".csv", isAdmin ? "template-locations" : "template-locations-orgs");
    } catch (error) {
      handleCreateToast("Error downloading the template", "error");
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        setFile([]);
      }}
      aria-labelledby="user-view-edit"
      sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: 800 } }}
      aria-describedby="user-view-edit-description"
    >
      <DialogTitle id="user-view-edit" sx={{ fontSize: "2rem !important", px: 8, pt: 8 }}>
        {t("IMPORT-LOCATION-TITLE")}
      </DialogTitle>
      <Divider />
      <IconButton
        aria-label="close"
        onClick={() => {
          handleClose();
          setFile([]);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon sx={{ fontSize: 24 }} />
      </IconButton>
      <DialogContent sx={{ px: 8 }}>
        <Typography variant="h5">
          You can{" "}
          <Typography component="span" color="primary" onClick={exportTemplate} sx={{ cursor: "pointer" }} fontWeight={700}>
            download a template here{" "}
          </Typography>
          o see an example of the required file.
        </Typography>
        <Typography variant="h5">Please note that:</Typography>
        <Typography variant="h5" component="li" mb={4}>
          {t("IMPORT-HELP-1")}
        </Typography>
        <Typography variant="h5" component="li" mb={4}>
          {t("IMPORT-HELP-2")}{" "}
          <Typography variant="h5" component="span" mb={4} fontWeight={700}>
            {t("IMPORT-HELP-21")}
          </Typography>
        </Typography>
        <Typography variant="h5" component="li" mb={4}>
          {t("IMPORT-HELP-3")}
        </Typography>
        <Box my={6}>
          <CSVUploader files={file} setFiles={setFile} />
        </Box>

        {loading && (
          <Alert severity="info" sx={{ "& .MuiPaper-root": { padding: "0" }, mt: 8 }} className="alert-no-padding">
            <Translations text="ALERT-INFO-IMPORT-NOT-CLOSE" />
          </Alert>
        )}
        {callStatus === "error" && (
          <Alert severity="error" sx={{ "& .MuiPaper-root": { padding: "0" }, mt: 8 }} className="alert-no-padding">
            <Translations text="ALERT-ERROR-GET-TOKEN-PING-ID" />
          </Alert>
        )}
        {callStatus === "success" && (
          <Alert severity="success" sx={{ "& .MuiPaper-root": { padding: "0" }, mt: 8 }} className="alert-no-padding">
            <Translations text="ALERT-SUCCESS-IMPORT" />
          </Alert>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: "space-between", mb: 4, px: 8 }}>
        <Button
          size="large"
          variant="text"
          sx={{ mr: 1 }}
          onClick={() => {
            handleClose();
            setFile([]);
          }}
          disabled={loading}
        >
          {t("BUTTON-CANCEL")}
        </Button>
        <Button
          size="large"
          variant="text"
          onClick={() => {
            handleConfirm(file[0]);
            setFile([]);
          }}
          disabled={file.length === 0 || loading}
        >
          {loading && <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} />}
          {t("BUTTON-IMPORT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
