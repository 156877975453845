//** React imports */
import { ChangeEvent, useState } from "react";

//** Components Imports */
import { CustomRadioIcons, CustomRadioIconsData, Input, InputApiCallInfiniteScroll } from "components";
import Translations from "layouts/components/Translations";
import { RequestAccessRoleEnum } from "pages/requestAccess/models/requestAccessEnum";

//** MUI Imports */
import { Box, Grid, Stack, Theme, Typography, useMediaQuery } from "@mui/material";

//** Hooks */
import { useRequestContext } from "pages/requestAccess/context";
import { useTranslation } from "react-i18next";
import { useGetOrganizationsInfiniteScroll, useInputValueContext, useLocationInfiniteScroll } from "hooks";
import useRequestAccess from "pages/requestAccess/hooks/useRequestAccess";
//** ICONS */
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { EMAIL_REGEX } from "models";
import InputApiCallCheckboxInfiniteScroll from "components/atoms/InputApiCallCheckbox/InputApiCallCheckboxInfiniteScroll";
import StepperButtons from "./StepperButtons";
import StepperButtonsEnd from "./stepperButtonsEnd";

export default function StepperContent() {
  //** Context */
  const {
    activeStep,
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    setActiveStep,
    setEmailSBD,
    emailSBD,
    email,
    title,
    location,
    multiOrgOrganizations,
  } = useRequestContext();
  const { selectedIconRadio, setSelectedIconRadio, organization, setOrganization, setTitle, setLocation, setMultiOrgOrganizations } =
    useRequestContext();

  const { getLocationsInfiniteScrollWithOutToken, locHasNextPage, locData, locPage, setLocPage, setLocData } = useLocationInfiniteScroll();

  //** Hooks */
  const { t } = useTranslation();

  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { requestAccess } = useRequestAccess();

  //** States Step 1*/
  const firstNameInput = useInputValueContext("", setFirstName);
  const lastNameInput = useInputValueContext("", setLastName);
  const phoneInput = useInputValueContext("", setPhone);
  const titleInput = useInputValueContext("", setTitle);

  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsValidEmail(EMAIL_REGEX.test(e.target.value));
  };

  const isLocationRelatedSelected =
    selectedIconRadio === RequestAccessRoleEnum.locationManager || selectedIconRadio === RequestAccessRoleEnum.locationViewer;

  // ** States Step 2*/

  const IconSize = underMd ? 20 : 26;
  const textAlign = underMd ? "left" : "center";

  const dataIcons: CustomRadioIconsData[] = [
    {
      isSelected: true,
      value: RequestAccessRoleEnum.multiOrgManager,
      title: `${t("CARD-REQUEST-TITLE-1")}`,
      icon: (
        <ApartmentIcon
          sx={{ fontSize: IconSize }}
          color={selectedIconRadio === RequestAccessRoleEnum.multiOrgManager ? "primary" : "inherit"}
        />
      ),
      content: (
        <Typography variant="h5" sx={{ my: "auto", textAlign }}>
          {`${t("CARD-REQUEST-CONTENT-1")}`}
        </Typography>
      ),
    },
    {
      isSelected: true,
      value: RequestAccessRoleEnum.multiOrgViewer,
      title: `${t("CARD-REQUEST-TITLE-2")}`,
      icon: (
        <VisibilityOutlinedIcon
          sx={{ fontSize: IconSize }}
          color={selectedIconRadio === RequestAccessRoleEnum.multiOrgViewer ? "primary" : "inherit"}
        />
      ),
      content: (
        <Typography variant="h5" sx={{ my: "auto", textAlign }}>
          {`${t("CARD-REQUEST-CONTENT-2")}`}
        </Typography>
      ),
    },
    {
      value: RequestAccessRoleEnum.organizationManager,
      title: `${t("CARD-REQUEST-TITLE-3")}`,
      icon: (
        <BusinessOutlinedIcon
          sx={{ fontSize: IconSize }}
          color={selectedIconRadio === RequestAccessRoleEnum.organizationManager ? "primary" : "inherit"}
        />
      ),
      content: (
        <Typography variant="h5" sx={{ my: "auto", textAlign }}>
          {`${t("CARD-REQUEST-CONTENT-3")}`}
        </Typography>
      ),
    },
    {
      value: RequestAccessRoleEnum.organizationViewer,
      title: `${t("CARD-REQUEST-TITLE-4")}`,
      icon: (
        <VisibilityOutlinedIcon
          sx={{ fontSize: IconSize }}
          color={selectedIconRadio === RequestAccessRoleEnum.organizationViewer ? "primary" : "inherit"}
        />
      ),
      content: (
        <Typography variant="h5" sx={{ my: "auto", textAlign }}>
          {`${t("CARD-REQUEST-CONTENT-4")}`}
        </Typography>
      ),
    },
    {
      value: RequestAccessRoleEnum.locationManager,
      title: `${t("CARD-REQUEST-TITLE-5")}`,
      icon: (
        <LocationOnOutlinedIcon
          sx={{ fontSize: IconSize }}
          color={selectedIconRadio === RequestAccessRoleEnum.locationManager ? "primary" : "inherit"}
        />
      ),
      content: (
        <Typography variant="h5" sx={{ my: "auto", textAlign }}>
          {`${t("CARD-REQUEST-CONTENT-5")}`}
        </Typography>
      ),
    },
    {
      value: RequestAccessRoleEnum.locationViewer,
      title: `${t("CARD-REQUEST-TITLE-6")}`,
      icon: (
        <VisibilityOutlinedIcon
          sx={{ fontSize: IconSize }}
          color={selectedIconRadio === RequestAccessRoleEnum.locationViewer ? "primary" : "inherit"}
        />
      ),
      content: (
        <Typography variant="h5" sx={{ my: "auto", textAlign }}>
          {`${t("CARD-REQUEST-CONTENT-6")}`}
        </Typography>
      ),
    },
  ];
  // ** States Step 3*/
  const [isValid, setIsValid] = useState<boolean>(false);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailSBD(e.target.value);
    const emailRegex = /@sbdinc.com/i;
    setIsValid(emailRegex.test(e.target.value));
  };

  // ** States Step 4*/
  const { getOrganizationsInfiniteScrollWithOutToken, hasNextPage, orgData, loading, orgPage, setOrgPage, setOrgData } =
    useGetOrganizationsInfiniteScroll();

  //** Steps submits */
  const handleStepOneSubmit = () => {
    if (!firstNameInput.value || !lastNameInput.value || !email || !phoneInput.value) return;
    setActiveStep(1);
  };

  const handleIconRadioChange = (prop: string | ChangeEvent<HTMLInputElement>) => {
    if (typeof prop === "string") {
      setSelectedIconRadio(prop);
    } else {
      setSelectedIconRadio((prop.target as HTMLInputElement).value);
    }
  };
  const selectedRadioMultiOrg = [RequestAccessRoleEnum.multiOrgManager, RequestAccessRoleEnum.multiOrgViewer];
  if (activeStep === 0) {
    return (
      <form key={0} onSubmit={handleStepOneSubmit}>
        <Stack spacing={8} direction="row" alignItems="center" mt={2}>
          <Input name="request-first-name" {...firstNameInput} label={`${t("INPUT-FIRST-NAME")} *`} fullWidth />

          <Input name="request-last-name" {...lastNameInput} label={`${t("INPUT-LAST-NAME")} *`} fullWidth />
        </Stack>
        <Stack spacing={8} direction="row" alignItems="center" mt={8}>
          <Input
            value={email}
            onChange={onChangeEmail}
            label={`${t("LOGIN-EMAIL")} *`}
            fullWidth
            name="request-email"
            id="outlined-basic"
            error={email?.length === 0 || !isValidEmail}
            helperText={!isValidEmail && `${email || ""}`.length > 0 ? `${t("INVALID-EMAIL")}` : `${t("ALL-REQUIRED.INPUT")}`}
          />

          <Input name="request-phone" {...phoneInput} label={`${t("INPUT-PHONE")} *`} fullWidth type="number" />
        </Stack>
        <Stack spacing={8} direction={underMd ? "column" : "row"} alignItems="center" mt={8} sx={{ display: "flex" }}>
          <Input name="users-title" value={title} onChange={titleInput.onChange} label={`${t("INPUT-TITLE")}`} fullWidth />
          <Box sx={{ width: "100%" }} />
        </Stack>
        <StepperButtons
          onClickPrev={() => {}}
          onClickNext={() => {
            setActiveStep(1);
          }}
          disabledPrev
          disabledNext={!firstNameInput.value || !lastNameInput.value || !email || !phoneInput.value || !isValidEmail}
        />
      </form>
    );
  }
  if (activeStep === 1) {
    return (
      <Box mb={underMd ? 40 : 0}>
        <Grid container spacing={4} justifyContent={underMd ? "flex-start" : "center"}>
          {dataIcons.map((item, index) => (
            <CustomRadioIcons
              key={index}
              data={dataIcons[index]}
              selected={selectedIconRadio}
              name="custom-radios-delivery"
              gridProps={{ md: 4, sm: 6, xs: 12 }}
              handleChange={handleIconRadioChange}
            />
          ))}
        </Grid>
        <StepperButtons
          onClickPrev={() => {
            setActiveStep(0);
          }}
          onClickNext={() => {
            setActiveStep(2);
          }}
        />
      </Box>
    );
  }
  if (activeStep === 2) {
    return (
      <>
        <Typography variant="h5" fontWeight={700}>
          <Translations text="STEP3-INPUT-TITLE" />
        </Typography>
        <Typography variant="h5" mb={12}>
          <Translations text="STEP3-INPUT-SUBTITLE" />
        </Typography>
        <Input
          name="request-sbd-email"
          value={emailSBD}
          onChange={onChange}
          label={`${t("INPUT-SBD-EMAIL")} *`}
          fullWidth
          placeholder="email@sbdinc.com"
          error={emailSBD.length > 0 && !isValid}
          helperText={`${t("NEED-SBD-EMAIL")}`}
        />
        <StepperButtons
          onClickPrev={() => {
            setActiveStep(1);
          }}
          onClickNext={() => {
            setActiveStep(3);
          }}
          disabledNext={emailSBD.length === 0 || !isValid}
        />
      </>
    );
  }
  if (activeStep === 3) {
    return (
      <>
        <Typography variant="h5" fontWeight={700}>
          <Translations text="STEP4-INPUT-TITLE" />
        </Typography>
        <Typography variant="h5" mb={12}>
          <Translations text="STEP4-INPUT-SUBTITLE" />
        </Typography>
        {selectedRadioMultiOrg.includes(selectedIconRadio as RequestAccessRoleEnum) ? (
          <InputApiCallCheckboxInfiniteScroll
            initialValue={multiOrgOrganizations}
            name="user-organization"
            setPropValue={setMultiOrgOrganizations}
            changeWithInputText
            apiCallFunction={getOrganizationsInfiniteScrollWithOutToken}
            loading={loading || false}
            label={`${t("REQUEST-TITLE-ORGNAIZATIONS")} *`}
            hasNextPage={hasNextPage}
            page={orgPage}
            options={orgData}
            setPage={setOrgPage}
            setOptions={setOrgData}
          />
        ) : (
          <InputApiCallInfiniteScroll
            initialValue={organization}
            name="loc-organization"
            setPropValue={setOrganization}
            changeWithInputText
            apiCallFunction={getOrganizationsInfiniteScrollWithOutToken}
            loading={loading}
            label={`${t("GLOBAL-TITLE-ORGNAIZATION")} *`}
            hasNextPage={hasNextPage}
            page={orgPage}
            options={orgData}
            setPage={setOrgPage}
            setOptions={setOrgData}
          />
        )}
        {organization?.id && isLocationRelatedSelected && (
          <Box mt={4}>
            <InputApiCallInfiniteScroll
              initialValue={location}
              name="loc.user"
              setPropValue={setLocation}
              changeWithInputText
              apiCallFunction={(name: string) => {
                getLocationsInfiniteScrollWithOutToken(name, !Array.isArray(organization) ? `${organization?.id}` : "");
              }}
              loading={loading}
              label={`${t("GLOBAL-TITLE-LOCATION")} *`}
              hasNextPage={locHasNextPage}
              page={locPage}
              options={locData}
              setPage={setLocPage}
              setOptions={setLocData}
              alwaysCallApi
            />
          </Box>
        )}
        <StepperButtonsEnd
          disabledNext={isLocationRelatedSelected ? !location?.id : !organization?.id && !multiOrgOrganizations.length}
          onClickPrev={() => {
            setActiveStep(2);
          }}
          onClickNext={requestAccess}
        />
      </>
    );
  }
  return <div />;
}
