//** MUI Imports */
import { CardContent, useMediaQuery } from "@mui/material";
import MuiCard, { CardProps } from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import { Box, Theme } from "@mui/system";

//**Components Imports */
import BlankLayout from "core/layouts/BlankLayout";
import { useRequestContext } from "pages/requestAccess/context";
import { RequestSteps, RequestSuccess } from "../../molecules";

const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: "80%" },
}));
export default function RequestAccessView() {
  const { activeStep } = useRequestContext();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const spacingX = underMd ? 8 : 18;
  const spacingTop = underMd ? 4 : 15;

  if (underMd) {
    return <Box m={8}>{activeStep !== 4 ? <RequestSteps /> : <RequestSuccess />}</Box>;
  }

  return (
    <BlankLayout>
      <Box className="content-center">
        <Card sx={{ maxWidth: 900, backgroundColor: "background.paper" }}>
          <CardContent sx={{ p: (theme) => `${theme.spacing(spacingX, spacingTop, spacingX)} !important` }}>
            {activeStep !== 4 ? <RequestSteps /> : <RequestSuccess />}
          </CardContent>
        </Card>
      </Box>
    </BlankLayout>
  );
}
