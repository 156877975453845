/* eslint-disable consistent-return */
import { useEffect, useState } from "react";
//** MUI Imports */
import WarningTooltip from "components/molecules/WarningTooltip/WarningTooltip";
import { Box, Divider, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import ReturnDataIcon from "components/molecules/ReturnDataIcon/ReturnDataIcon";

//** Context */
import { useLocationContext } from "pages/locations/context";
import { useGlobalContext } from "context/globalContext";
//**Hooks */
import { useTranslation } from "react-i18next";
import { useFetchAndLoad, useInputValueContext, useRoleGuard } from "hooks";
import useSetGoogleMapData from "pages/locations/pages/locationsInformation/hooks/useSetGoogleMapData";

//**Services */
import { getAllRegionsAPI } from "pages/locations/services";
import { IRegion, Roles } from "models";

//** Components Imports */
import { AutoComplete, GoogleMapsMUI, Input } from "components";
import MapLocations from "components/atoms/Map/MapLocations/MapLocations";
import useAutorizeUserByRole from "utilities/hooks/useAuthorizeUserRole";

export default function AddressStep({ loading }: { loading?: boolean }) {
  //** Context */
  const { address1, address2, city, postalCode, view, state, googleMapsInputs, mapId, region, alertRequiredFields, locSelectedMark } =
    useLocationContext();
  const { setAddress2, setCity, setPostalCode, setMarketCountry, setState, setRegion, setLocHasChanges } = useLocationContext();
  const { isUserAuthorized } = useAutorizeUserByRole();

  const { marketCountry } = useLocationContext();
  const { positions } = useGlobalContext();

  //** Hooks */
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.organizationManager, Roles.locationManager, Roles.multiOrgManager]);

  const address2Input = useInputValueContext("", setAddress2);
  const cityInput = useInputValueContext("", setCity);
  const postalCodeInput = useInputValueContext("", setPostalCode);
  const stateInput = useInputValueContext("", setState);

  const fetchRegion = useFetchAndLoad();

  const [regionOptions, setRegionOptions] = useState<IRegion[]>([]);
  const [regionOptionsDropdown, setRegionOptionsDropdown] = useState<IRegion[]>([]);
  const [marketOptionsDropdown, setMarketOptionsDropdown] = useState<any>([]);

  const compareByLabel = (a: any, b: any) => {
    return a.label.localeCompare(b.label);
  };

  const getCountryData = async () => {
    //Get all the regions/markets data on all the countrys
    if (region?.id) {
      const marketsOnRegion = regionOptions.find((item: IRegion) => item.code === region?.id);
      const markets = marketsOnRegion?.markets?.map((market) => ({ label: market.code, id: market.code }));
      const sorterdMarker = markets?.sort(compareByLabel);
      setMarketOptionsDropdown(sorterdMarker ? [...sorterdMarker] : []);
    } else {
      const autocompleteData = regionOptions.map((item: IRegion) => ({ markets: item.markets }));

      //create a regions/markets array data on all the countrys information
      let array: { label: string; id: string }[] = [];
      autocompleteData.forEach((item) => {
        item.markets?.forEach((market: any) => {
          array = [...array, { label: market.code, id: market.code }];
        });
      });
      const sorterdMarker = array?.sort(compareByLabel);
      setMarketOptionsDropdown([...sorterdMarker]);
    }
  };

  // eslint-disable-next-line consistent-return
  const getRegionData = async () => {
    //Get country data sort by name
    try {
      const query: any = { sort: "asc(name)" };
      const response = await fetchRegion.callEndpoint(getAllRegionsAPI({ query }));
      const { data } = response;

      //Get all the regions/markets data on all the countrys
      const autocompleteData: IRegion[] = data.result.items.map((item: IRegion) => ({ label: item.code, id: item.code }));

      setRegionOptionsDropdown(autocompleteData);
      if (regionOptions.length === 0) setRegionOptions(data?.result?.items);
      return autocompleteData;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRegionData();
  }, []);

  const changeControllerFn = () => setLocHasChanges(true);

  //** When this component load on first time, call to GM to get the info and compare with the saved data to show the warnings */
  const { onPlaceChanged, getGMDataForInfoView, getMapsData, loading: loadingGetMaker } = useSetGoogleMapData();

  useEffect(() => {
    if (!locSelectedMark) return;
    getMapsData(locSelectedMark);
    changeControllerFn();
  }, [locSelectedMark]);

  const marketGoogleCompare = !!marketCountry?.id && !!googleMapsInputs?.market && googleMapsInputs?.market !== marketCountry.label;
  const regionGoogleCompare = !!region?.id && !!googleMapsInputs?.region && googleMapsInputs?.region !== region.label;
  const stateGoogleCompare = !!state && !!googleMapsInputs?.state && googleMapsInputs?.state !== state;
  const cityGoogleCompare = !!city && !!googleMapsInputs?.city && googleMapsInputs?.city !== city;
  const postalCodeGoogleCompare = !!postalCode && !!googleMapsInputs?.postalCode && googleMapsInputs?.postalCode !== postalCode;

  //**Required inputs management */
  const isRequiedFieldEmpty = view === "create" ? alertRequiredFields[1] : true;

  return (
    <Box mt={underMd ? 0 : 12}>
      <Typography variant={underMd ? "h4" : "h3"} sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
        {`${t("TITLE-LOCATION-ADDRESS")}`}
      </Typography>
      <Stack direction={underMd ? "column" : "row"} spacing={4} my={6}>
        <Box sx={{ width: "100%" }}>
          {address1 || view === "create" ? (
            <GoogleMapsMUI
              onPlaceChanged={onPlaceChanged}
              initialValue={mapId}
              label={`${t("INPUT-ADDRESS-1")}*`}
              disabled={!authUpdateOrganization}
              getGMDataForInfoView={getGMDataForInfoView}
              requiredFields={!mapId && isRequiedFieldEmpty}
              alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
              mapId={mapId}
            />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}
        </Box>
        <Input
          name="loc-address2"
          value={address2}
          onChange={address2Input.onChange}
          label={`${t("INPUT-ADDRESS-2")}`}
          fullWidth
          disabled={!authUpdateOrganization}
          changeControllerFn={changeControllerFn}
        />
      </Stack>
      <Stack direction="row" spacing={4} mb={8}>
        <AutoComplete
          disabled={!authUpdateOrganization}
          fullWidth
          value={region}
          options={regionOptionsDropdown}
          setValue={(valueState: any) => {
            setRegion(valueState);
            changeControllerFn();
          }}
          name="loc-region"
          blurOnSelect
          warning={regionGoogleCompare}
          inputLabel={`${t("INPUT-REGION")}*`}
          requiredFields={(region?.label?.length === 0 || region === null) && isRequiedFieldEmpty}
          alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
          endAdornment={
            regionGoogleCompare &&
            googleMapsInputs?.region && (
              <ReturnDataIcon onClick={() => setRegion({ label: googleMapsInputs?.region, id: googleMapsInputs?.region })} />
            )
          }
        />

        <AutoComplete
          disabled={!authUpdateOrganization}
          fullWidth
          value={marketCountry}
          options={marketOptionsDropdown}
          setValue={(valueState: any) => {
            setMarketCountry(valueState);
            changeControllerFn();
          }}
          name="loc-market-country"
          blurOnSelect
          warning={marketGoogleCompare}
          inputLabel={`${t("INPUT-MARKET-COUNTRY")}*`}
          onClick={getCountryData}
          requiredFields={(marketCountry?.label?.length === 0 || marketCountry === null) && isRequiedFieldEmpty}
          alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
          endAdornment={
            marketGoogleCompare &&
            googleMapsInputs?.market && (
              <ReturnDataIcon onClick={() => setMarketCountry({ label: googleMapsInputs?.market, id: googleMapsInputs?.market })} />
            )
          }
        />
      </Stack>

      <Stack direction="row" spacing={4} mb={8}>
        <Input
          name="loc-state"
          value={state}
          onChange={stateInput.onChange}
          label={`${t("INPUT-STATE")}*`}
          fullWidth
          disabled={!authUpdateOrganization}
          startAdornment={stateGoogleCompare ? <WarningTooltip /> : null}
          color={stateGoogleCompare && "warning"}
          focused={stateGoogleCompare}
          error={state?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
          endAdornment={
            stateGoogleCompare && googleMapsInputs?.state && <ReturnDataIcon onClick={() => setState(googleMapsInputs.state)} />
          }
          changeControllerFn={changeControllerFn}
        />
        <Input
          name="loc-city"
          value={city}
          onChange={cityInput.onChange}
          label={`${t("INPUT-CITY")}*`}
          fullWidth
          disabled={!authUpdateOrganization}
          startAdornment={cityGoogleCompare ? <WarningTooltip /> : null}
          color={cityGoogleCompare && "warning"}
          focused={cityGoogleCompare}
          error={city?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
          endAdornment={cityGoogleCompare && googleMapsInputs?.city && <ReturnDataIcon onClick={() => setCity(googleMapsInputs.city)} />}
          changeControllerFn={changeControllerFn}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
        <Input
          name="loc-postal-code"
          value={postalCode}
          onChange={postalCodeInput.onChange}
          label={`${t("INPUT-POSTAL-CODE")}*`}
          fullWidth
          disabled={!authUpdateOrganization}
          startAdornment={postalCodeGoogleCompare ? <WarningTooltip /> : null}
          color={postalCodeGoogleCompare && "warning"}
          focused={postalCodeGoogleCompare}
          error={postalCode?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
          endAdornment={
            postalCodeGoogleCompare &&
            googleMapsInputs?.postalCode && <ReturnDataIcon onClick={() => setPostalCode(googleMapsInputs.postalCode)} />
          }
          changeControllerFn={changeControllerFn}
        />
        <Box sx={{ width: "100%" }} />
      </Stack>
      <Box mb={8} />
      <MapLocations
        loadingGetMaker={loadingGetMaker}
        positions={positions}
        mapHeight={underMd ? "400px" : "70vh"}
        options={{
          mapTypeControlOptions: { position: 3 },
          draggable: isUserAuthorized([Roles.superAdmin, Roles.organizationManager]),
        }}
        loading={loading}
      />

      <Divider />
    </Box>
  );
}
