//** Components imports */
import { Box, Theme, useMediaQuery } from "@mui/material";
import { SectionsTitles } from "components";
//**Hooks */
import { useTranslation } from "react-i18next";
//** Context */
import { useLocationContext } from "pages/locations/context";
import OpenHoursInputs from "./OpenHoursInputs";

export default function OpenHours() {
  const { openingHours, setOpeningHours, googleMapsInputs } = useLocationContext();
  //** Hooks */
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Box mt={underMd ? 0 : 12}>
      <SectionsTitles h3 title={`${t("GLOBAL-TITLE-BUSINESS-INFO")}`} subTitle={`${t("STEP-TITLE-OPEN-OPEN-HOURS")}`} mb={4} />

      {openingHours?.map((data: any, index: number) => (
        <OpenHoursInputs
          data={data}
          index={index}
          openingHours={openingHours}
          setOpeningHours={setOpeningHours}
          googleMapsInputs={googleMapsInputs}
        />
      ))}
    </Box>
  );
}
