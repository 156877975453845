import { Box } from "@mui/material";
import { useCreateLocation } from "pages/locations/hooks";
import { Dispatch, SetStateAction } from "react";
import { ButtonStepsCreate, FacilityInformation, OpenHours } from "../molecules";
import ModalGoBackLocation from "../molecules/ModalGoBackLocation/ModalGoBackLocation";
import { AddressStep, AditionalStep, BasicInformationLocationStep, ContactStep } from "../organism";

export default function StepsLocCreateMobile({
  activeStep,
  setActiveStep,
  urlToGo,
}: {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  urlToGo: string;
}) {
  const { handleCreateLocation, loading } = useCreateLocation();

  //** Validate required fields */

  return (
    <Box>
      <Box mx={8}>
        {activeStep === 0 && <BasicInformationLocationStep create />}
        {activeStep === 1 && <FacilityInformation />}
        {activeStep === 2 && (
          <Box>
            <AddressStep />
            <ContactStep />
          </Box>
        )}
        {activeStep === 3 && (
          <Box>
            <OpenHours />
            <AditionalStep />
          </Box>
        )}
      </Box>
      <ButtonStepsCreate
        disabled={loading}
        onClickLastStep={handleCreateLocation}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        urlToGoBack={urlToGo}
      />
      <ModalGoBackLocation />
    </Box>
  );
}
