//** MUI Imports */
import { Box, Stack, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { Button } from "components";
import { PrivateRoutes, Roles } from "models";
//**Hooks */
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
// import { useRoleGuard } from "hooks";
//**Models */
import isUrl from "is-url";
//**Redux */
import { RoleGuard, UnderMdGuard } from "guards";
import ButtonsStepperLocForm from "pages/locations/components/mobile/ButtonsStepperLocForm";
import { useLocationContext } from "pages/locations/context";
import { purposeCodeforAplicableBrand } from "../../../../constants/purposesToConditions";
import { useUpdateLocation } from "../../hooks";

interface ButtonUpdateLocationProps {
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
  activeStep?: number;
  urlToGoBack?: string;
}
export default function ButtonUpdateLocation({ setActiveStep, activeStep, urlToGoBack }: ButtonUpdateLocationProps) {
  //**Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { updateLocation, completeLoading } = useUpdateLocation();
  const { id } = useParams();
  //**Context */
  //** Step */
  const { purpose, isValidEmail, isValidPhone, keyContacts, locHasChanges, setGoBackModalLoc } = useLocationContext();
  //** Others Step */
  const { name, organization, applicableBrand, initalUrl } = useLocationContext();
  const { email, phone, website, marketCountry } = useLocationContext();
  const { city, postalCode, latitude, longitude, state, region } = useLocationContext();
  //* location Services */
  const { displayOrder } = useLocationContext();
  //* SBDUSE*/
  const { isValidSBDUSEmails } = useLocationContext();
  //** See which apps are selected */
  const isAplicableBrandRequired =
    purpose.some((item) => purposeCodeforAplicableBrand.includes(`${item.code}`)) && applicableBrand.length === 0;

  const basicReqFields = !name || !organization?.label;

  const addressReqFields =
    !marketCountry?.label ||
    !region?.label ||
    !city ||
    !postalCode ||
    !latitude ||
    !longitude ||
    !state ||
    (email?.length > 0 && !isValidEmail) ||
    (phone?.length > 0 && !isValidPhone) ||
    (!isUrl(`${initalUrl}${website}`) && website.length > 0);

  const businessReqFields =
    purpose.length === 0 ||
    // This fields are mandaroty only if the app is selected
    (purpose.findIndex((item) => item.code === "OR") !== -1 ? !displayOrder : false) ||
    isAplicableBrandRequired;

  const disableButton =
    basicReqFields ||
    addressReqFields ||
    businessReqFields ||
    !isValidSBDUSEmails.mngDirectorEmail ||
    keyContacts?.some((item) => !item.isValidEmail);

  const mobileStyles = {
    position: "fixed",
    bottom: 0,
    width: "100%",
    px: 8,
    pb: 8,
    backgroundColor: "background.paper",
    zIndex: 10000,
  };

  //**Mobile disable logic */
  const disableStep1 = basicReqFields && activeStep === 0;
  const disableStep3 = addressReqFields && activeStep === 2;

  if (underMd && activeStep !== 4) {
    return (
      <ButtonsStepperLocForm
        activeStep={activeStep || 0}
        setActiveStep={setActiveStep || (() => {})}
        disableStep1={disableStep1}
        disableStep2={false}
        disableStep3={disableStep3}
        urlToGo={urlToGoBack || `/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`}
      />
    );
  }

  return (
    <Box sx={underMd ? { ...mobileStyles } : {}}>
      <Stack direction="row" gap={4} mt={8} width="100%" justifyContent="space-between">
        <UnderMdGuard>
          <Button
            name="loc-cancel-update"
            onClick={() => {
              const urlToGo = `/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`;
              if (locHasChanges) return setGoBackModalLoc({ open: true, urlToGo: `${urlToGo}` });
              return navigate(`${urlToGo}`);
            }}
            label={t("BUTTON-CANCEL")}
            size="large"
            color="secondary"
            variant="outlined"
          />
        </UnderMdGuard>
        <UnderMdGuard showOnMobile>
          <Button
            name="org-cancel-create"
            onClick={() => {
              setActiveStep && setActiveStep((prev) => prev - 1);
            }}
            label={t("BUTTON-BACK")}
            size="large"
            variant="outlined"
          />
        </UnderMdGuard>
        <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationManager, Roles.locationManager, Roles.multiOrgManager]}>
          <Button
            name="loc-update"
            onClick={updateLocation}
            label={t("BUTTON-UPDATE")}
            size="large"
            disabled={disableButton || completeLoading}
          />
        </RoleGuard>
      </Stack>
    </Box>
  );
}
