//** Components imports */
import { CardHeaderLogin } from "components";
//** Hooks */
import { useTranslation } from "react-i18next";

export default function StepperHeader() {
  //** Hooks */
  const { t } = useTranslation();

  return (
    <CardHeaderLogin
      title={`${t("REQUEST-STEP1-TITLE")}`}
      subTitle={`${t("REQUEST-STEP1-SUBTITLE")}`}
      subTitle2={`${t("REQUEST-STEP1-SUBTITLE2")}`}
      paddingSecondTitle={1.5}
      variant="h5"
    />
  );
}
